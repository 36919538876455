<template>
  <div>
    <v-dialog v-model="dialog" width="1200">
      <TargetHeatMap :info="currentGroup.info" />
    </v-dialog>
    <div v-if="showLegend && compoundTable.length > 0" class="legend">
      <v-col :cols="v_col" class="pa-0 white-background">
        <div class="compound-card">
          <span>No.</span>
          <div class="structure"> 構造式 </div>
          <v-row no-gutters class="mb-0">
            <template v-for="(c, j) in compoundTable[0]['Potency']">
              <p :key="`$pot_${j}`" class="legend-cell">{{ c.name }}</p>
            </template>
          </v-row>
          <v-row no-gutters class="mb-0">
            <template v-for="(c, j) in compoundTable[0]['Property']">
              <p :key="`$pro_${j}`" class="legend-cell">{{ c.name }}</p>
            </template>
          </v-row>
          <v-row no-gutters class="mb-0">
            <template v-for="(c, j) in compoundTable[0]['Toxicity']">
              <p :key="`$tox_${j}`" class="legend-cell">{{ c.name }}</p>
            </template>
          </v-row>
          <v-row no-gutters class="mb-0">
            <template v-for="(c, j) in compoundTable[0]['ADME']">
              <p :key="`$adm_${j}`" class="legend-cell">{{ c.name }}</p>
            </template>
          </v-row>
          <v-row no-gutters class="mb-0">
            <template v-for="(c, j) in compoundTable[0]['Filters']">
              <p :key="`$fil_${j}`" class="legend-cell">{{ c.name }}</p>
            </template>
          </v-row>
        </div>
      </v-col>
    </div>
    <v-row v-for="r in nbRows" :key="r">
      <v-col
        v-for="(compound, col) in rowCompounds(r - 1)"
        :key="`${r}_${col}`"
        :cols="v_col"
      >
        <div class="compound-card">
          <span>{{ (r-1) * nbCols + col + 1 }}</span>
          <v-badge
            content="参照化合物"
            :value="compounds[(r-1) * nbCols + col].is_ref === true"
            color="rgb(153,0,153)"
            class="ml-2"
            bordered
          />
          <div
            class="structure"
            v-html="compounds[(r-1) * nbCols + col] ?
              compounds[(r-1) * nbCols + col].structure : ''"
          />
          <v-row no-gutters class="mb-2">
            <template v-for="(c, j) in compound['Potency']">
              <span v-if="c.activity == 'clickable group-cell'"
                    :key="`${r}_pot_${j}`"
                    :class="`cell clickable group-cell`"
                    @click="openDialog(compounds[(r-1) * nbCols + col], c.name)"
              >
                <strong class="group-inactive">
                  {{ c.value }}
                </strong>
                <span>
                  / {{ targetCounts[c.name] }}
                </span>
              </span>
              <span v-else
                    :key="`${r}_pot_${j}`"
                    :class="`cell ${c.activity}`"
              >
                {{ c.value }}
              </span>
            </template>
          </v-row>
          <v-row no-gutters class="mb-2">
            <template v-for="(c, j) in compound['Property']">
              <span :key="`${r}_pro_${j}`" :class="`cell ${c.activity}`">
                {{ c.value }}
              </span>
            </template>
          </v-row>
          <v-row no-gutters class="mb-2">
            <template v-for="(c, j) in compound['Toxicity']">
              <span v-if="c.activity == 'clickable group-cell'"
                    :key="`${r}_tox_${j}`"
                    :class="`cell clickable group-cell`"
                    @click="openDialog(compounds[(r-1) * nbCols + col], c.name)"
              >
                <strong class="group-inactive">
                  {{ c.value }}
                </strong>
                <span>
                  / {{ targetCounts[c.name] }}
                </span>
              </span>
              <span v-else
                    :key="`${r}_tox_${j}`"
                    :class="`cell ${c.activity}`"
              >
                {{ c.value }}
              </span>
            </template>
          </v-row>
          <v-row no-gutters class="mb-2">
            <template v-for="(c, j) in compound['ADME']">
              <span v-if="c.activity == 'clickable group-cell'"
                    :key="`${r}_adm_${j}`"
                    :class="`cell clickable group-cell`"
                    @click="openDialog(compounds[(r-1) * nbCols + col], c.name)"
              >
                <strong class="group-inactive">
                  {{ c.value }}
                </strong>
                <span>
                  / {{ targetCounts[c.name] }}
                </span>
              </span>
              <span v-else
                    :key="`${r}_adm_${j}`"
                    :class="`cell ${c.activity}`"
              >
                {{ c.value }}
              </span>
            </template>
          </v-row>
          <v-row no-gutters class="mb-2">
            <template v-for="(c, j) in compound['Filters']">
              <span
                :key="`${r}_fil_${j}`"
                :class="`cell ${c.activity}`"
              >
                {{ c.value }}
              </span>
            </template>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { formatNumber, activityCssClass } from '@/mixins/utils';
import TargetHeatMap from '@/components/design/TargetHeatMap';

export default {
  name: 'ResultCompoundTiles',
  components: {
    TargetHeatMap
  },
  props: {
    compounds: {
      type: Array,
      default: () => []
    },
    profile: {
      type: Array,
      default: () => []
    },
    showLegend: {
      type: Boolean,
      required: true
    },
    filters: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      potencyTargets: [],
      propertyTargets: [],
      toxicityTargets: [],
      admeTargets: [],
      targets: {
        'Potency': [],
        'Property': [],
        'Toxicity': [],
        'ADME': []
      },
      compoundTable: [],
      nbRows: 1,
      dialog: false,
      currentGroup: {},
      targetCounts: {}
    };
  },
  computed: {
    breakpoint: function() {
      return this.$vuetify.breakpoint.name;
    },
    nbCols: function() {
      switch (this.$vuetify.breakpoint.name) {
      case 'xl':
      case 'lg':
        return 4;
      default:
        return 3;
      }
    },
    v_col: function() {
      return Math.floor(12 / this.nbCols);
    }
  },
  watch: {
    compounds: {
      handler() {
        this.compoundTable = [];
        this.targets = {
          'Potency': [],
          'Property': [],
          'Toxicity': [],
          'ADME': []
        };
        this.nbRows = Math.ceil(this.compounds.length / 3);
        // Profiles read from sdf isn't calculated
        const profileTargets = this.profile;
        for (const p of profileTargets) {
          if (p?.predictionType === 'MultipleFeaturePrediction') {
            this.targetCounts[p.predictionDisplayName] = this.multipleTargetCount(p.predictionDisplayName);
            this.targets[p.category].push(p.predictionDisplayName);
          } else {
            this.targetCounts[p.predictionDisplayName] = 1;
            this.targets[p.category].push(p.predictionDisplayName);
          }
        }
        for (const compound of this.compounds) {
          const table = {
            'Potency': [],
            'Property': [],
            'Toxicity': [],
            'ADME': [],
            'Filters': []
          };
          for (const category in this.targets) {
            for (const target of this.targets[category]) {
              if (target === null) {
                continue;
              }
              const profileInfo = this.profileInfo(target);
              if (profileInfo?.predictionType === 'MultipleFeaturePrediction') {
                const value = this.profileInactiveCount(compound, profileInfo.predictionDisplayName);
                table[category].push({
                  'value': isNaN(value) ? '' : value,
                  'activity': 'clickable group-cell',
                  'name': profileInfo.predictionDisplayName
                });
              } else {
                const value = formatNumber(compound[target]);
                const activity = this.activity(value, target);
                table[category].push({
                  'value': isNaN(value) ? '' : value,
                  'activity': activity,
                  'name': target
                });
              }
            };
          }
          for (const filter of this.filters) {
            table.Filters.push({
              'value': compound[filter],
              'activity': compound[filter] === 0 ? 'profile-active' : 'profile-inactive',
              'name': filter
            });
          }
          this.compoundTable.push(table);
        }
      },
      deep: true
    }
  },
  methods: {
    profileInfo(target) {
      return this.profile.find(p => p.initialName === target ||
                              p.predictionDisplayName === target);
    },
    activity(value, target) {
      const profileInfo = this.profileInfo(target);
      if (profileInfo) {
        return activityCssClass(value, profileInfo);
      } else {
        return '';
      }
    },
    rowCompounds(row) {
      const first = row * this.nbCols;
      const last = Math.min(
        row * this.nbCols + this.nbCols,
        this.compoundTable.length
      );
      return this.compoundTable.slice(first, last);
    },
    generateTargetGroupData(compound, predictionModelName) {
      const newTargets = {};
      const profileInfo = this.profileInfo(predictionModelName);
      const targets = Object.keys(compound).map((name) => ({
        name: name.includes(predictionModelName) ? name.replace(predictionModelName + '_', '') : false,
        activity: activityCssClass(compound[name], profileInfo),
        predicted: compound[name]
      }));
      newTargets.info = { targets: targets.filter(t => t.name !== false) };
      return newTargets;
    },
    profileInactiveCount(compound, predictionModelName) {
      const activities = ['profile-inactive'];
      const targets = this.generateTargetGroupData(compound, predictionModelName).info;
      const count = activities.reduce((acc, n) => {
        acc[n] = 0;
        return acc;
      }, {});
      targets.targets.forEach((e) => {
        if (e.activity in count) {
          count[e.activity] += 1;
        }
      });
      return count[activities];
    },
    multipleTargetCount(predictionModelName) {
      if (this.compounds.length > 0) {
        return Object.keys(this.compounds[0]).reduce(
          (count, i) => i.includes(predictionModelName) ? count + 1 : count, 0);
      }
      return 0;
    },
    openDialog(compound, predictionModelName) {
      this.currentGroup = this.generateTargetGroupData(compound, predictionModelName);
      this.dialog = true;
    }
  }
};
</script>

<style scoped>
.compound-card {
    margin: 5px;
    padding: 5px;
    border: solid 1px;
}
.compound-card .structure {
    text-align: center;
}
.cell {
    outline: solid 1px;
    width: 56px;
    height: 22px;
    text-align: center;
}
.legend-cell {
    outline: solid 1px;
    width: 56px;
    height: 45px;
    text-align: center;
    padding-left: 4px;
    padding-right: 4px;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.legend {
    position: sticky;
    top: 114px;
    background-color: transparent;
}
.white-background {
    background-color: white;
}
</style>
