<template>
  <v-container grid-list-md>
    <v-layout row wrap align-center justify-center fill-height>
      <v-flex xs12 sm8 lg4 md5>
        <v-card class="pa-4">
          <v-card-title>
            <span class="headline">パスワード変更</span>
          </v-card-title>

          <v-spacer />

          <v-card-text>
            <v-form
              ref="form"
              v-model="isFormvalid"
              @keyup.native.enter="updatePassword"
            >
              <v-container>
                <v-text-field
                  v-model="currentPassword"
                  type="password"
                  label="現在のパスワード"
                  :rules="[v => !!v || '現在のパスワードは必須です']"
                  required
                />
                <v-text-field
                  v-model="newPassword"
                  type="password"
                  label="新しいパスワード"
                  :rules="[v => !!v || '新しいパスワードは必須です']"
                  required
                />
                <v-text-field
                  v-model="confirmNewPassword"
                  type="password"
                  label="新しいパスワードの確認"
                  :rules="[
                    v => !!v || '新しいパスワードの確認は必須です',
                    v => v === newPassword || 'パスワードが一致しません'
                  ]"
                  required
                />
              </v-container>
              <v-btn
                class="primary"
                :loading="loading"
                :disabled="!isFormvalid || loading"
                @click="updatePassword"
              >
                更新
              </v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { showSuccessDialog, showErrorDialog } from '@/mixins/utils';

export default {
  name: 'PasswordUpdate',
  data: () => ({
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',
    isFormvalid: false,
    loading: false
  }),
  methods: {
    updatePassword() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        const self = this;
        const dataToSend = {
          current_password: this.currentPassword,
          new_password: this.newPassword
        };
        this.api.updateUserPassword(dataToSend, function() {
          self.loading = false;
          showSuccessDialog('Success', 'パスワードが更新されました');
          // The following line will reset the 3 variables to null
          self.$refs.form.reset();
        }, function(error) {
          let errorMessage = '現在のパスワードが間違っています';
          if (error.data?.non_field_errors) {
            errorMessage = '新しいパスワードに問題があります:<br>';
            error.data.non_field_errors.forEach((msg, index) => {
              errorMessage += `\n${index + 1}. ${msg}<br>`;
            });
          }
          self.loading = false;
          showErrorDialog('Error', errorMessage);
        });
      }
    }
  }
};
</script>
