<template>
  <Error :error-code="403" />
</template>

<script>
import Error from '@/components/Error';
export default {
  name: 'ForbiddenPage',
  components: {
    Error: Error
  }
};
</script>
