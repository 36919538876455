<template>
  <v-container class="pa-0" fluid>
    <v-card class="ma-0">
      <v-card-title class="pa-0">
        <v-toolbar dense dark :color="themeColor">
          <v-col class="d-flex justify-left">
            <v-toolbar-title class="black--text">{{ title }}</v-toolbar-title>
          </v-col>
        </v-toolbar>
      </v-card-title>
      <v-row v-if="inPreparation">
        <v-col cols="12" class="d-flex align-center justify-center">
          <div class="preparation">工事中</div>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="4" class="d-flex align-center justify-center">
          <v-btn
            class="start-button text-pre-wrap"
            :href="startButtonItem.to"
            target="_blank"
          >
            <div class="ma-5">{{ startButtonItem.text }}</div>
          </v-btn>
        </v-col>
        <v-col cols="4">
          <v-list dense flat>
            <v-subheader>ユーザーマニュアル</v-subheader>
            <v-list-item-group color="primary">
              <v-list-item
                v-for="(item, i) in manualItems"
                :key="i" :href="item.to"
                target="_blank"
              >
                <v-list-item-icon>
                  <v-icon v-text="item.icon" />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.text" />
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-col>
        <v-col cols="4">
          <v-list dense flat>
            <v-subheader>リンク集</v-subheader>
            <v-list-item-group color="primary">
              <v-list-item
                v-for="(item, i) in linkItems"
                :key="i" :href="item.to"
              >
                <v-list-item-icon>
                  <v-icon v-text="item.icon" />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.text" />
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'PortalCard',
  props: {
    inPreparation: {
      type: Boolean,
      default: false
    },
    themeColor: {
      type: String,
      default: 'primary lighten-3'
    },
    title: {
      type: String,
      default: ''
    },
    startButtonItem: {
      type: Object,
      default: () => {}
    },
    manualItems: {
      type: Array,
      default: () => []
    },
    linkItems: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({}),
  methods: {}
};
</script>

<style scoped>
.v-application .start-button {
  text-align: center;
  height: 100px;
  width: 200px;
  color: white;
  background-color: gray;
  font-size: 20px;
}

.preparation {
  margin: 40px;
  font-size: 80px;
}
</style>
