<template>
  <v-container>
    <v-dialog v-model="dialog" max-width="500">
      <ProjectForm
        :project-to-edit="projectToEdit"
        @save="onProjectSave"
        @saveAndGo="onProjectSaveAndGo"
        @cancel="dialog=false"
      />
    </v-dialog>
    <v-hover v-slot="{ hover }">
      <div
        style="display: inline-block;"
        class="ma-2"
        @click="openDialog"
      >
        <v-btn
          fab
          max-height="54px"
          outlined
          color="primary"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        <v-scroll-x-transition>
          <v-chip
            v-show="hover"
            color="primary"
            class="clickable"
            large
            style="position:relative; left:-56px"
          >
            新しいプロジェクトを作成
          </v-chip>
        </v-scroll-x-transition>
      </div>
    </v-hover>
    <v-card
      v-for="project of projects"
      :key="project.id"
      class="mx-auto mb-2"
    >
      <v-card-title>
        {{ project.name }}
        <v-tooltip v-if="project.creator_id == currentUserId" top>
          <template #activator="{ on }">
            <v-icon
              color="blue"
              v-on="{ ...on, ...$listeners }"
              @click="edit(project)"
            >
              mdi-pencil
            </v-icon>
          </template>
          <span>更新</span>
        </v-tooltip>
        <v-btn
          color="primary"
          class="ml-8 elevation-1"
          text
          :to="designHref(project.id)"
        >
          新規デザイン設定
        </v-btn>
        <v-spacer />
        <v-chip
          class="ma-2"
          :color="getChipColor(project.group_display)"
          outlined
        >
          {{ project.group_display }}
        </v-chip>
        <v-btn
          v-if="project.creator_id == currentUserId"
          color="error"
          class="mr-8 elevation-1"
          right
          @click="confirmProjectDeletion(project)"
        >
          削除
        </v-btn>
        <span
          style="font-size:12px;"
        >
          作成日 {{ project.created_at.split(" ")[0] }}
        </span>
      </v-card-title>
      <v-card-subtitle class="text--primary">
        {{ project.description }}
      </v-card-subtitle>
      <v-card-text>
        {{ project.memo }}
      </v-card-text>
      <v-card-actions>
        <v-btn
          v-show="project.design_count > 0"
          text
          class="expand-btn"
          @click="project.show = !project.show"
        >
          <v-icon v-if="project.show">mdi-chevron-up</v-icon>
          <v-icon v-else>mdi-chevron-down</v-icon>
          実施済みデザインを見る({{ project.design_count }})
        </v-btn>
        <v-spacer />
        <v-tooltip v-if="project.show" top>
          <template #activator="{ on, attrs }">
            <div v-on="on">
              <v-btn
                :disabled="project.compare.length < 2"
                color="primary"
                v-bind="attrs"
                v-on="on"
                @click="onClickCompare(project.id)"
              >
                <span v-if="project.step === 1">結果比較</span>
                <span v-else @click="project.refreshResults = false">戻る</span>
              </v-btn>
            </div>
          </template>
          <span v-if="project.step === 1">2つ以上のデザイン情報を<br>クリックして選択する</span>
          <span v-else>デザイン一覧に戻る</span>
        </v-tooltip>
      </v-card-actions>

      <v-expand-transition>
        <v-window v-if="project.show" v-model="project.step">
          <v-window-item :value="1">
            <ProjectDesignList
              :project="project"
              :modules="modules"
              :selected-designs="project.compare"
              @clickDesign="onClickDesign"
              @deleteDesign="onDesignDelete"
            />
          </v-window-item>

          <v-window-item :value="2">
            <DesignCompareView
              :designs="project.compare"
              :step="project.step"
              :refresh-results="project.refreshResults"
            />
          </v-window-item>
        </v-window>
      </v-expand-transition>
    </v-card>
  </v-container>
</template>

<script>
import router from '@/router';
import ProjectDesignList from '@/components/design/Project/ProjectDesignList';
import DesignCompareView from '@/pages/design/CompareView';
import ProjectForm from '@/components/design/Project/ProjectForm';
import {
  showConfirmDialog,
  showSuccessDialog,
  showErrorDialog
} from '@/mixins/utils';

export default {
  name: 'ProjectList',
  components: {
    ProjectDesignList,
    DesignCompareView,
    ProjectForm
  },
  data() {
    return {
      projects: [],
      modules: {},
      dialog: false,
      currentUserId: this.$session.get('user').id,
      projectToEdit: {}
    };
  },
  mounted() {
    this.checkLoggedIn(this.$session);
    this.loadProjects();
  },
  methods: {
    loadProjects() {
      const self = this;
      this.api.loadProjects(
        function(list) {
          self.projects = list.map((p) => ({
            ...p,
            show: false,
            compare: [],
            step: 1,
            refreshResults: false
          }));
        },
        function(error) {
          if (error.response.status !== 401) {
            console.log(error.response.data);
          }
        }
      );
    },
    onClickDesign(projectId, design) {
      const indx = this.projects.findIndex((p) => p.id === projectId);
      if (indx >= 0) {
        if (design.selected) {
          this.projects[indx].compare.push(design);
        } else {
          this.projects[indx].compare = this.projects[
            indx
          ].compare.filter((item) => {
            return item.id !== design.id;
          });
        }
        this.projects[indx].refreshResults = true;
      }
    },
    onClickCompare(projectId) {
      const indx = this.projects.findIndex((p) => p.id === projectId);
      const step = this.projects[indx].step;
      if (indx >= 0) {
        this.projects[indx].step = step === 1 ? 2 : 1;
      }
    },
    onProjectSave(project) {
      this.dialog = false;
      this.loadProjects();
    },
    onProjectSaveAndGo(project) {
      this.dialog = false;
      router.push({
        name: 'ProjectDesignSettings',
        params: { projectId: project.id }
      });
    },
    designHref(projectId) {
      return {
        name: 'ProjectDesignSettings',
        params: { projectId: projectId }
      };
    },
    confirmProjectDeletion(project) {
      const self = this;
      showConfirmDialog({
        title: `${project.name} を削除しようとしています。`,
        html: 'この操作は取り消せません。プロジェクトの全てのデザインも削除されます。<br><br> 本当に削除しますか?',
        approveCB: () => {
          self.api.deleteProject(
            project.id,
            function(data) {
              showSuccessDialog('', `${project.name} は削除されました。`);
              self.loadProjects();
            },
            function(e) {
              showErrorDialog(
                '予想外のエラーが発生しました。',
                'もう一度実行するか、解決しない場合は管理者に知らせてください。'
              );
            }
          );
        }
      });
    },
    edit(project) {
      this.projectToEdit = project;
      this.dialog = true;
    },
    openDialog() {
      this.projectToEdit = null;
      this.dialog = true;
    },
    getChipColor(displayName) {
      switch (displayName) {
      case '全員': return 'orange';
      case '自分のみ': return 'green';
      default: return 'blue';
      }
    },
    onDesignDelete(projectId) {
      const self = this;
      this.api.loadProject(projectId,
        function(project) {
          const indx = self.projects.findIndex((p) => p.id === projectId);
          if (indx > -1) {
            self.projects[indx].design_count = project.design_count;
          }
        },
        function(error) {
          if (error.response.status !== 401) {
            console.log(error.response.data);
          }
        });
    }
  }
};
</script>

<style scoped>
.clickable {
    cursor: pointer;
}
.expand-btn {
    color: rgba(0, 0, 0, 0.54);
}
</style>
