const title = {
  namespaced: true,
  state: {
    title: null
  },
  mutations: {
    setTitle(state, value) {
      state.title = value;
    }
  },
  actions: {
    updateTitle({ commit }, value) {
      commit('setTitle', value);
    }
  },
  getters: {
    title(state) {
      return state.title;
    }
  }
};

export default title;
