<template>
  <v-container>
    <v-row class="flex-nowrap">
      <v-card v-for="(item, i) in processCounts"
              :key="`process_${i}`"
              :class="processHeaders[i] === 'My Jobs Completed' ? 'mr-15 mb-1' : 'mr-1 mb-1'"
              class="card-fixed-size"
      >
        <v-col class="pa-0">
          <v-card-title
            class="justify-center"
            :class="processCounts[i] > 0 ? i.replace(/_/g, '-') : ''"
          >
            {{ item }}
          </v-card-title>
          <v-card-subtitle class="subtitle-fixed-size">
            {{ processHeaders[i] }}
          </v-card-subtitle>
        </v-col>
      </v-card>
    </v-row>
    <v-row class="pt-6">
      <v-col class="pa-0">
        <v-data-table
          class="elevation-1"
          :headers="headers"
          :items="executionsToDisplay"
          :loading="isTableLoading"
          loading-text="ロード中..."
          no-data-text="データがありません。"
          item-key="subId"
          :items-per-page="itemsPerPage"
          :height="tableHeight"
          fixed-header
          hide-default-footer
          sort-by="created_at"
          sort-desc
          multi-sort
          @pagination="onPaginationChange"
        >
          <template #top="{ pagination, options, updateOptions }">
            <v-data-footer
              :pagination="pagination"
              :options="options"
              :items-per-page-options="itemPerPageOptions"
              items-per-page-text="$vuetify.dataTable.itemsPerPageText"
              :show-first-last-page="true"
              @update:options="updateOptions"
            >
              <template #prepend>
                <v-col cols="3" class="pb-0">
                  <v-select
                    v-model="selectedJobTypes"
                    :items="jobTypes"
                    item-text="text"
                    item-value="value"
                    label="Filter by job type"
                    multiple
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="3" class="pb-0">
                  <v-select
                    v-model="selectedJobStatuses"
                    :items="jobStatuses"
                    label="Filter by status"
                    multiple
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
              </template>
            </v-data-footer>
          </template>
          <template #[`item.name`]="{ item }">
            <a :href="item.columns.url">
              {{ item.columns.idField }}
            </a>
          </template>
          <template #[`item.action`]="{ item }">
            <CancelExecutionButton
              v-if="isCancelableExecution(item)"
              :execution-id="item.id"
              :execution-type="item.type"
              :name="item.columns.dialog"
              @refresh="refreshJobStatus"
            />
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ITEMS_PER_PAGE_OPTIONS } from '@/env';
import {
  tableHeightWithMargin,
  showErrorDialog
} from '@/mixins/utils';
import CancelExecutionButton from '@/components/CancelExecutionButton';

export default {
  name: 'JobsTable',
  components: {
    CancelExecutionButton
  },
  data() {
    return {
      type: '',
      isTableLoading: true,
      itemsPerPage:
        Number(localStorage.getItem('jobs-ipp')) ||
        ITEMS_PER_PAGE_OPTIONS[0],
      itemPerPageOptions: ITEMS_PER_PAGE_OPTIONS,
      executions: [],
      processCounts: {},
      processHeaders: {
        'maximum': 'Max Jobs',
        'total_working': 'Total Running',
        'total_waiting': 'Total Waiting',
        'my_jobs_running': 'My Jobs Running',
        'my_jobs_waiting': 'My Jobs Waiting',
        'my_jobs_completed': 'My Jobs Completed',
        'max_cpu': 'Max CPU',
        'available_cpu': 'Available CPU'
      },
      headers: [
        { text: 'Job', value: 'name' },
        { text: 'Status', value: 'status' },
        { text: 'Created at', value: 'created_at' },
        { text: 'Begin datetime', value: 'begin_at' },
        { text: 'Action', value: 'action', sortable: false }
      ],
      selectedJobTypes: [],
      selectedJobStatuses: [],
      jobTypes: [
        { text: 'Single execution', value: 'module' },
        { text: 'Workflow execution', value: 'workflow' },
        { text: 'Project Design', value: 'project_design' },
        { text: 'Test execution', value: 'test' }
      ]
    };
  },
  computed: {
    tableHeight: function() {
      return tableHeightWithMargin(90);
    },
    executionsToDisplay() {
      return this.executions.filter(execution => {
        // Define variables to trigger Vue's reactivity.
        // By using a single return statement, reactivity is not triggered.
        const matchesType = this.selectedJobTypes.length === 0 ||
                            this.selectedJobTypes.includes(execution.type) ||
                            (execution.type === 'profile_prediction' &&
                              this.selectedJobTypes.includes('project_design'));
        const matchesStatus = this.selectedJobStatuses.length === 0 ||
                              this.selectedJobStatuses.includes(execution.status);
        return matchesType && matchesStatus;
      }).map((item, index) => ({
        subId: index,
        columns: this.identifyName(item.id, item.type, item.module_id || item.project_design_id),
        ...item
      }));
    },
    jobStatuses() {
      const statuses = this.executions.map(execution => execution.status);
      return [...new Set(statuses.sort())];
    }
  },
  mounted() {
    this.checkLoggedIn(this.$session);
    this.getJobsList();
    this.processExecutionStatus();
  },
  methods: {
    isCancelableExecution(item) {
      const isCancelableStatus =
        item.end_at === null && item.status !== 'Canceled';
      return isCancelableStatus;
    },
    onPaginationChange(pagination) {
      if (pagination.itemsPerPage) {
        this.itemsPerPage = pagination.itemsPerPage;
        localStorage.setItem('jobs-ipp', pagination.itemsPerPage);
      }
    },
    getJobsList() {
      const self = this;
      this.api.getJobsList(
        function(executions) {
          self.executions = executions.map((execution, index) => ({
            ...execution,
            status: self.setStatus(execution)
          }));
          self.isTableLoading = false;
        },
        function() {
          self.isTableLoading = false;
        }
      );
    },
    processExecutionStatus() {
      const self = this;
      this.api.getProcessExecutionStatus(
        function(executions) {
          self.processCounts = executions;
          self.processCounts.max_cpu = 0;
          self.processCounts.available_cpu = 0;
        },
        function(e) {
          showErrorDialog(
            'Failed to get the execution status',

            `Try again or let an administrator
                          know if the problem persists.`
          );
        }
      );
    },
    identifyName(id, type, sourceId = '') {
      const columns = {};
      if (type === 'module') {
        columns.idField = `Single execution #${id}`;
        columns.dialog = `Single execution #${id}`;
        columns.url = `/executions/${id}`;
      } else if (type === 'project_design') {
        columns.idField = `Project Design #${sourceId}`;
        columns.dialog = `Execution of Project Design #${sourceId}`;
        columns.url = `/project/design/${sourceId}`;
      } else if (type === 'workflow') {
        columns.idField = `Workflow execution #${id}`;
        columns.dialog = `Workflow execution #${id}`;
        columns.url = `/workflow/execution/${id}`;
      } else if (type === 'test') {
        columns.idField = `Test execution #${id}`;
        columns.dialog = `Test execution #${id}`;
        columns.url = `/models/${sourceId}/test`;
      } else if (type === 'profile_prediction') {
        columns.idField = `Project Design #${sourceId}`;
        columns.dialog = `Profile Prediction execution for Project Design #${sourceId}`;
        columns.url = `/project/design/${sourceId}`;
      }
      return columns;
    },
    refreshJobStatus() {
      this.getJobsList();
      this.processExecutionStatus();
    },
    setStatus(execution) {
      if (execution.end_at !== null) {
        return execution.status;
      } else if (execution.begin_at !== null) {
        return 'Running';
      } else {
        return 'In the queue';
      }
    }
  }
};
</script>

<style>
.my-jobs-running {
  color:rgb(0,90,255);
}

.total-waiting {
  color:#FF4B00;
}

.my-jobs-waiting {
  color:#FF4B00;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.card-fixed-size {
  min-width: 100px;
  max-width: 100px;
  height: 80px;
}

.subtitle-fixed-size {
  font-size: 12px;
  line-height: 1.2;
  padding: 0 8px;
  text-align: center;
}
</style>
