import Vue from 'vue';
import axios from 'axios';
import router from '@/router';
import { API_BASE_URI } from '@/env';
import store from '@/store';

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || ''
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;

// eslint-disable-next-line vue/max-len
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

// axios.defaults.xsrfCookieName = 'csrftoken'
// axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN'

const config = {
  baseURL: API_BASE_URI || 'http://localhost:8000',
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'X-CSRFTOKEN'
};

const _axios = axios.create(config);
const _axiosNoResponseInterceptor = axios.create(config);

_axios.interceptors.request.use(
  function(config) {
    if (Vue.token) {
      config.headers = { Authorization: `JWT ${Vue.token}` };
    }
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function(response) {
    // Do something with response data
    return response;
  },
  function(error) {
    if (error.response?.status) {
      store.commit('error/setCode', error.response.status);
      switch (error.response.status) {
      case 401:
        Vue.prototype.$session.remove('token');
        if (router.currentRoute.path !== router.resolve({ name: 'Auth' }).href) {
          router.push({ name: 'Auth' });
        }
        break;
      case 403:
        store.commit('error/setDisplayPage', true);
        break;
      case 502:
        alert('Server unavailable');
      }
      return Promise.reject(error.response);
    }
  }
);

_axiosNoResponseInterceptor.interceptors.request.use(
  function(config) {
    if (Vue.token) {
      config.headers = { Authorization: `JWT ${Vue.token}` };
    }
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error.response);
  }
);

const AxiosPlugin = {
  install(Vue, options) {
    Vue.axios = _axios;
    window.axios = _axios;
    Object.defineProperties(Vue.prototype, {
      axios: {
        get() {
          return _axios;
        }
      },
      $axios: {
        get() {
          return _axios;
        }
      }
    });
    Vue.axiosNoResponseInterceptor = _axiosNoResponseInterceptor;
    window.axiosNoResponseInterceptor = _axiosNoResponseInterceptor;
    Object.defineProperties(Vue.prototype, {
      axiosNoResponseInterceptor: {
        get() {
          return _axiosNoResponseInterceptor;
        }
      },
      $axiosNoResponseInterceptor: {
        get() {
          return _axiosNoResponseInterceptor;
        }
      }
    });
  }
};

export default AxiosPlugin;
