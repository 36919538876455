<template>
  <v-container class="pb-0">
    <div class="d-flex mb-4">
      <v-btn
        color="primary"
        to="/models/registration"
      >
        Create a new AI model
      </v-btn>
      <v-spacer />
      <v-checkbox
        v-model="isShownNotHiddenModules"
        class="mt-1"
        hide-detail
        label="Public models"
      />
      <v-checkbox
        v-model="isShownHiddenModules"
        class="mt-1 mx-6"
        hide-detail
        label="Hidden models"
      />
      <v-tooltip top>
        <template #activator="{ on, attrs }">
          <v-btn
            color="primary"
            icon
            v-bind="attrs"
            v-on="on"
            @click="refresh"
          >
            <v-icon dark>mdi-refresh</v-icon>
          </v-btn>
        </template>
        <span>Refresh</span>
      </v-tooltip>
    </div>

    <ManageableModelTable
      :is-loading="isLoading"
      :modules="displayedModules"
      :staff-settings="staffSettings"
      @toggleHidden="toggleHidden"
      @refresh="refresh"
    />
  </v-container>
</template>

<script>
import ManageableModelTable from '@/components/ManageableModelTable';
import { showErrorDialog } from '@/mixins/utils';

export default {
  name: 'ManageableModelList',
  components: {
    ManageableModelTable
  },
  data() {
    return {
      modules: [],
      isLoading: true,
      staffSettings: false,
      isShownNotHiddenModules: true,
      isShownHiddenModules: true
    };
  },
  computed: {
    displayedModules: function() {
      return this.modules.filter(
        m => (this.isShownNotHiddenModules && !m.hidden) ||
             (this.isShownHiddenModules && m.hidden)
      );
    }
  },
  watch: {
    $route(to, from) {
      this.init();
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    refresh() {
      this.fetchAllModels();
    },
    fetchAllModels() {
      this.isLoading = true;
      const self = this;
      this.api.loadManageableModels(
        null,
        this.staffSettings ? 'all' : 'user',
        function(list) {
          self.modules = list;
          self.isLoading = false;
        }, function(error) {
          console.log(error);
          self.isLoading = false;
        });
    },
    toggleHidden(moduleId) {
      const moduleIndex = this.modules.findIndex(m => m.id === moduleId);
      const data = { hidden: !this.modules[moduleIndex].hidden };
      const self = this;
      this.api.patchMLModules(moduleId, data,
        function(module) {
          self.modules[moduleIndex].hidden = module.hidden;
          self.refresh();
        }, function() {
          showErrorDialog(
            'An unexpected error occurred',
            'Try again or let an administrator know if the problem persists.'
          );
        });
    },
    init() {
      this.checkLoggedIn(this.$session);
      this.$session.set('callBackPageName', this.$route.name);
      this.staffSettings = this.$route.name === 'StaffModelList';
      this.fetchAllModels();
    }
  }
};
</script>
