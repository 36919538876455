<template>
  <v-data-table
    class="elevation-1"
    :headers="visibleColumns"
    :items="filteredItems"
    :loading="isLoading"
    :options.sync="options"
    loading-text="ロード中..."
    no-data-text="データがありません。"
    :page-count="pageCount"
    :server-items-length="itemCount"
    multi-sort
    item-key="id"
    :items-per-page="itemsPerPage"
    fixed-header
    hide-default-footer
    @pagination="onPaginationChange"
  >
    <template #top="{ pagination, options, updateOptions }">
      <div class="d-flex align-center justify-space-between">
        <v-checkbox
          v-if="columns.some((column) => column.value === 'structure')"
          v-model="columns.find((column) => column.value === 'structure').visible"
          label="Display structures"
        />
        <v-data-footer
          :pagination="pagination"
          :disable-items-per-page="noResultsToDisplay"
          :options="options"
          :items-per-page-options="itemsPerPageOptions"
          items-per-page-text="$vuetify.dataTable.itemsPerPageText"
          :show-first-last-page="true"
          @update:options="updateOptions"
        />
      </div>
    </template>
    <template #[`item.structure`]="{ item }">
      <span v-html="item.structure" />
    </template>
  </v-data-table>
</template>

<script>
import { ITEMS_PER_PAGE_OPTIONS } from '@/env';
// eslint-disable-next-line no-unused-vars
import { formatNumber } from '@/mixins/utils';

export default {
  name: 'CsvItemList',
  components: {},
  props: {
    csvData: {
      type: Object,
      default: () => null
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    preventOptionsUpdate: {
      type: Boolean,
      required: true
    },
    pageCount: {
      type: Number,
      required: true
    },
    itemCount: {
      type: Number,
      required: true
    },
    noResultsToDisplay: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      columns: [],
      items: [],
      itemsPerPage:
        Number(localStorage.getItem('exec-res-ipp')) ||
        ITEMS_PER_PAGE_OPTIONS[0],
      itemsPerPageOptions: ITEMS_PER_PAGE_OPTIONS.slice(0, 3),
      options: {},
      filteredItems: [],
      filters: {}
    };
  },
  computed: {
    visibleColumns() {
      return this.columns.filter(column => column.visible);
    }
  },
  watch: {
    csvData: {
      handler: function() {
        const visibleColumns = this.visibleColumns;
        this.columns = [];
        this.items = [];
        this.filteredItems = [];
        this.filters = {};
        if (this.csvData == null) {
          return;
        }

        for (const i in this.csvData.columns) {
          const column = this.csvData.columns[i];
          if (column === 'Link' || column === 'URL') {
            continue;
          }
          const info = {
            text: column,
            value: column,
            filterable: true,
            sortable: column !== 'structure',
            visible: visibleColumns.length === 0 || visibleColumns.some((c) => c.value === column)
          };
          this.columns.push(info);
        }
        for (const i in this.csvData.items) {
          const item = this.csvData.items[i];
          const entry = {};
          for (const index in item) {
            const key = this.csvData.columns[index];
            entry[key] = formatNumber(String(item[index]));
          }
          this.items.push(entry);
          this.filteredItems.push(entry);
        }
      },
      deep: true
    },
    options: {
      handler() {
        if (!this.preventOptionsUpdate) {
          this.updateData();
        }
      },
      deep: true
    },
    filters: {
      handler: function() {
        this.filteredItems = this.items.filter((item) =>
          Object.entries(this.filters).every(([k, v]) =>
            v === '' || item[k].includes(v)
          )
        );
      },
      deep: true
    }
  },
  methods: {
    onPaginationChange(pagination) {
      if (pagination.itemsPerPage) {
        this.itemsPerPage = pagination.itemsPerPage;
        localStorage.setItem('exec-res-ipp', pagination.itemsPerPage);
      }
    },
    updateData() {
      this.$emit('updateData', this.options);
    }
  }
};
</script>
