<template>
  <router-link :to="to">
    <v-tooltip top>
      <template #activator="{ on }">
        <v-icon v-bind="$attrs" :color="color" v-on="{ ...on, ...$listeners }">
          {{ icon }}
        </v-icon>
      </template>
      <span>{{ tooltip }}</span>
    </v-tooltip>
  </router-link>
</template>

<script>
export default {
  name: 'LinkIcon',
  props: {
    to: {
      type: String,
      default: ''
    },
    tooltip: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      required: true
    },
    color: {
      type: String,
      default: ''
    }
  }
};
</script>
