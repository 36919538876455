<template>
  <div style="display: inline-block;">
    <v-btn color="info" dark @click="dialog = true">
      <v-icon dark left>mdi-help-circle-outline</v-icon>
      <span>Scalingについて</span>
    </v-btn>

    <v-dialog v-model="dialog" max-width="600">
      <v-card>
        <v-img :src="imageSrc" />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      imageSrc: '/scaling_types.jpg'
    };
  }
};
</script>
