<template>
  <v-card class="pa-5">
    <v-card-title class="break">
      Choose who can access the results of this execution.
    </v-card-title>
    <v-card-text>
      <v-container fluid>
        <v-radio-group
          v-model="selectedChoice"
          row
        >
          <v-radio
            v-for="(choice, index) in choices"
            :key="index"
            :label="choice.label"
            :value="choice.value"
          />
        </v-radio-group>
      </v-container>
      <v-select
        v-if="selectedChoice === 'ToGroup'"
        v-model="group"
        :items="groupOptions"
        label="Group"
        item-value="id"
        item-text="name"
        :rules="[(v) => selectedChoice && !!v || 'Group is required']"
        required
        dense
      />
    </v-card-text>
    <v-card-actions>
      <v-btn color="alert" @click="cancel">Cancel</v-btn>
      <v-spacer />
      <v-btn color="warning" @click="execute">Confirm</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

export default {
  name: 'ExecutionVisibility',
  props: {
    choices: {
      type: Array,
      default: () => []
    },
    groupOptions: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      selectedChoice: 'OnlyMe',
      group: null
    };
  },
  methods: {
    cancel() {
      this.$emit('cancel');
    },
    execute() {
      this.$emit('confirm', this.selectedChoice, this.group);
    }
  }
};
</script>

<style scoped>
.break{
  word-break: normal !important;
}
</style>
