const error = {
  namespaced: true,
  state: {
    code: undefined,
    display: false
  },
  mutations: {
    clear(state) {
      state.code = undefined;
      state.display = false;
    },
    setCode(state, code) {
      state.code = code;
    },
    setDisplayPage(state, val) {
      state.display = val;
    }
  },
  getters: {
    displayPage(state) {
      return state.display;
    },
    code(state) {
      return state.code;
    }
  }
};

export default error;
