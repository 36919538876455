<template>
  <v-dialog :value="showDialog" persistent width="600" :key="dialogKey">
    <v-card class="mx-auto">
      <v-card-title>
        {{ model.name }}
      </v-card-title>
      <v-card-subtitle
        class="text--primary description-block"
      >
        {{ model.description }}
      </v-card-subtitle>
      <v-card-text>
        <v-row v-if="isFeaturePredictionType()" no-gutters>
          <v-col cols="3">Prediction unit: </v-col>
          <v-col cols="9">{{ model.unit }}</v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="3">Documentation: </v-col>
          <v-col cols="9">
            <span
              v-if="model.document_name"
              class="downloadLink"
              @click="downloadDocument"
            >
              {{ model.document_name }}
            </span>
          </v-col>
        </v-row>
        <v-row
          v-if="Object.keys(moduleInputs).length !== 0 || Object.keys(osInputs).length !== 0"
        >
          <v-col cols="12">
            実行に必要な項目を入力してください。
          </v-col>
          <v-col cols="6">
            <div
              class="text-subtitle-1"
              v-text="'Prediction parameters'"
            />
            <v-form
              ref="targetForm"
              lazy-validation
              @submit.prevent
            >
              <div
                v-for="(info, name) in moduleInputs"
                :key="name"
              >
                <ModuleInput
                  :name="name"
                  :info="info"
                  :all-required="true"
                  :value="parameters[name] || files[name]"
                  @changeParameter="onChangeTargetParams"
                  @setFile="onChangeTargetFiles"
                />
              </div>
            </v-form>
          </v-col>
          <v-divider vertical />
          <v-col cols="6">
            <div
              class="text-subtitle-1"
              v-text="'Objective server parameters'"
            />
            <v-form
              ref="rewardForm"
              lazy-validation
              @submit.prevent
            >
              <div
                v-for="(info, name) in osInputs"
                :key="name"
              >
                <ModuleInput
                  :name="name"
                  :info="info"
                  :all-required="true"
                  :value="osParameters[name] || osFiles[name]"
                  @changeParameter="onChangeOSParams"
                  @setFile="onChangeOSFiles"
                />
              </div>
            </v-form>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col>このModuleには設定するオプションの引数はありません。</v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="warning"
          text
          @click="cancelDialog()"
        >
          キャンセル
        </v-btn>
        <v-btn
          v-if="Object.keys(moduleInputs).length !== 0 || Object.keys(osInputs).length !== 0"
          color="info"
          text
          @click="confirmDialog()"
        >
          有効化
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { showErrorDialog } from '@/mixins/utils';
import consts from '@/store/consts';
import ModuleInput from '@/components/ModuleInput.vue';

export default {
  name: 'ProfileParametersInput',
  components: {
    ModuleInput
  },
  data() {
    return {
      model: {},
      linkedOS: {},
      parameters: Object(),
      files: Object(),
      osParameters: Object(),
      osFiles: Object(),
      mandatoryField: [
        { ...consts.MandatoryFields.cmpFile },
        { ...consts.MandatoryFields.cmpStrucName },
        { ...consts.MandatoryFields.cmpIdName }
      ],
      showDialog: false,
      dialogKey: ''
    };
  },
  computed: {
    moduleInputs() {
      const removeFields = this.model.type === 'Single Feature Prediction'
        ? this.mandatoryField.concat([{ ...consts.MandatoryFields.predValName }])
        : this.mandatoryField;
      if (this.model.config) {
        const inputs = JSON.parse(this.model.config).input || {};
        for (const field of removeFields.map((f) => f.name)) {
          delete inputs[field];
        }
        if (this.model.has_linked_uncertainty) {
          inputs.compute_uncertainty = {
            Description: 'Compute Uncertainty',
            type: 'bool',
            required: true,
            default: 'False'
          };
        }
        return inputs;
      }
      return {};
    },
    osInputs() {
      if (this.linkedOS.config) {
        return JSON.parse(this.linkedOS.config).input || {};
      }
      return {};
    }
  },
  methods: {
    openDialog(modelId, parameters, files, osParameters, osFiles) {
      this.getModule(modelId);
      this.parameters = parameters;
      this.files = files;
      this.osParameters = osParameters;
      this.osFiles = osFiles;
      this.dialogKey = `${modelId}_${Date.now()}`;
      this.showDialog = true;
    },
    getModule(moduleId) {
      this.parameters = Object();
      this.files = Object();
      const self = this;
      this.api.getMLModule(moduleId, function(model) {
        self.model = model;
        if (model.linked_mlm_id !== null) {
          self.getLinkedOSModel(model.linked_mlm_id);
        }
      }, null, false, true);
    },
    getLinkedOSModel(osId) {
      const self = this;
      this.api.getMLModule(osId, function(model) {
        self.linkedOS = model;
      }, null, false, true);
    },
    downloadDocument() {
      if (this.model?.document_name) {
        const self = this;
        this.api.downloadDocumentFile(
          self.model.id,
          function(response) {
            self.api.downloadFileHelper(
              response, self.model.document_name);
          },
          function() {
            showErrorDialog(
              'ファイルが見つかりません。',
              '指定されたファイルを見つけることができませんでした。'
            );
          },
          true
        );
      }
    },
    isFeaturePredictionType() {
      const moduleType = this.model.type ? this.model.type.replace(/\s+/g, '') : '';
      return this.model &&
        consts.FeaturePredTypes.includes(moduleType);
    },
    onChangeTargetParams(...args) {
      const [name, value] = args;
      this.parameters[name] = value;
    },
    onChangeTargetFiles(...args) {
      const [name, file] = args;
      this.files[name] = file;
    },
    onChangeOSParams(...args) {
      const [name, value] = args;
      this.osParameters[name] = value;
    },
    onChangeOSFiles(...args) {
      const [name, file] = args;
      this.osFiles[name] = file;
    },
    confirmDialog() {
      this.$emit('confirm', this.model.id, this.parameters, this.files, this.osParameters, this.osFiles);
      this.parameters = Object();
      this.files = Object();
      this.osParameters = Object();
      this.osFiles = Object();
      this.showDialog = false;
    },
    cancelDialog() {
      this.showDialog = false;
    }
  }
};
</script>
