<template>
  <v-container>
    <v-form ref="form" @submit.prevent>
      <template v-if="!createView">
        <v-card class="mt-5 mb-5">
          <v-card-title>
            {{ workflow.name }}
            <v-btn
              v-if="!createView"
              class="ma-2 ml-auto"
              @click="openWorkflowExecution()"
            >
              Execute
            </v-btn>
          </v-card-title>

          <v-divider />
          <!-- eslint-disable-next-line vue/max-len -->
          <v-card-text class="description-block">{{ descriptionShown }}</v-card-text>
          <ReadMoreSwitch
            v-if="workflow.description.split('\n').length > 3"
            class="pa-4"
            @onClick="switchDescription"
          />

          <StepList
            :process="process"
            @addStep="addStep"
            @removeStep="removeStep"
          />
        </v-card>
      </template>
      <template v-else>
        <v-text-field
          v-model.trim="workflow.name"
          label="Name"
          class="mb-4"
          :rules="[v => !!v.trim() || 'Name is required']"
        />
        <v-textarea
          v-model="workflow.description"
          label="Description"
          class="mb-4"
          :rules="[v => !!v.trim() || 'Description is required',
                   v => v.length <= 200 || 'Description can only contain up to 200 characters',
                   v => ( v.match( /\n/g ) || [] ).length < 10 || 'Description can only contain up to 10 lines'
          ]"
        />
        <StepList
          :process="process"
          @addStep="addStep"
          @removeStep="removeStep"
        />
      </template>
      <template v-if="!createView" class="mt-5 mb-5">
        <h2 class="header">Executions</h2>
        <WorkflowExecutionTable
          :is-loading="isLoading"
          :executions="workflow.executions"
          @refresh="getWorkflow"
        />
      </template>
      <v-row v-if="createView">
        <v-col>
          <v-btn style="float: left" text color="primary" @click="save">
            Create workflow
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import router from '../../router';
import {
  showConfirmDialog, showSuccessDialog, showErrorDialog
} from '@/mixins/utils';
import WorkflowExecutionTable from '@/components/WorkflowExecutionTable.vue';
import StepList from '@/components/StepList.vue';
import ReadMoreSwitch from '@/components/ReadMoreSwitch';

export default {
  name: 'WorkflowEditor',
  components: {
    WorkflowExecutionTable,
    StepList,
    ReadMoreSwitch
  },
  data() {
    return {
      id: Number(this.$route.params.id),
      workflow: { name: '', description: '', executions: [] },
      process: [],
      createView: !this.$route.params.id,
      showFullDescription: false,
      isLoading: true
    };
  },
  computed: {
    descriptionShown() {
      return this.showFullDescription
        ? this.workflow.description
        : this.workflow.description.split('\n', 3).join('\n');
    }
  },
  mounted() {
    this.checkLoggedIn(this.$session);
    this.getWorkflow();
  },
  methods: {
    getWorkflow() {
      const self = this;
      if (self.id) {
        this.api.getWorkflow(
          this.id,
          function(workflow) {
            self.workflow = workflow;
            self.workflow.executions = self.workflow.executions.sort(function(a, b) {
              return (a.updated_at > b.updated_at) ? -1 : 1;
            });
            self.process = workflow.config.steps.map((step) => {
              return {
                step: step.num,
                module: workflow.modules.find(
                  (m) => m.id === step.module_id)
              };
            });
            self.isLoading = false;
          },
          function(error) {
            console.log(error);
            self.isLoading = false;
          }
        );
      }
    },
    save() {
      if (this.isValid()) {
        const title = `Are you sure you want 
          to register the following workflow?`;
        let message = '';
        this.process.filter(p => p.module)
          .map((p, i) => `Step ${i + 1}: ${p.module.name}`)
          .forEach((m, indx) => {
            if (indx > 0) {
              message += `<br/>${'&nbsp;'.repeat(indx * 2)} → `;
            }
            message += m;
          });
        showConfirmDialog({
          title: title,
          html: message,
          approveCB: this.postWorkflow
        });
      }
    },
    isValid() {
      if (!this.$refs.form.validate()) {
        const y = document
          .querySelector('.error--text')
          .getBoundingClientRect().top + window.pageYOffset - 48;
        window.scrollTo({ 'top': y, 'behavior': 'smooth' });
      } else {
        let message = '';
        if (this.process.length < 2 ||
                (this.process.length === 2 &&
                this.process.some(p => p.module === null))
        ) {
          message = 'Workflow needs at least 2 steps.';
        }
        if (message !== '') {
          showErrorDialog(message);
        }
        return message === '';
      }
    },
    postWorkflow() {
      this.workflow.moduleIds = this.process.filter(p => p.module)
        .map(p => p.module.id);
      this.api.registerWorkflow(this.workflow, this.files,
        function(response) {
          showSuccessDialog('Workflow Registered');
          router.push({ name: 'WorkflowList' });
        }, function(error) {
          showErrorDialog(
            'Something went wrong.',
            error.data
          );
        }
      );
    },
    openWorkflowExecution: function() {
      router.push({ name: 'WorkflowExecution', params: { id: this.id } });
    },
    addStep() {
      this.process.push({
        step: this.process.length + 1,
        module: null
      });
    },
    removeStep(index) {
      this.process.splice(index, 1);
    },
    switchDescription() {
      this.showFullDescription = !this.showFullDescription;
    }
  }
};
</script>
