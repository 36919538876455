import router from '@/router';

const isLoginPage = () => {
  return router.currentRoute.path === router.resolve({ name: 'Auth' }).href;
};

const Helper = {
  install(Vue, options) {
    Vue.prototype.checkLoggedIn = (session) => {
      session.start();
      if (!session.has('token') && !isLoginPage()) {
        router.push({ name: 'Auth' });
      } else {
        Vue.token = session.get('token');
        Vue.prototype.token = session.get('token');
      }
    };
  }
};

export default Helper;
