<template>
  <v-text-field
    class="shrink mx-1 pb-1 pt-0"
    append-icon="mdi-magnify"
    label="Search"
    single-line
    hide-details
    @input="$emit('input', $event)"
  />
</template>

<script>
export default {
  name: 'SearchField',
  data() {
    return {
    };
  }
};
</script>
