import Vue from 'vue';

import Router from 'vue-router';
import {
  REFRESH_TOKEN_TIME,
  TOKEN_EXPIRED_TIME,
  MAINTENANCE_MODE
} from '@/env';

import Auth from '@/pages/Auth';
import MLPlatform from '@/pages/MLPlatform';
import MLList from '@/pages/MLList';
import PasswordUpdate from '@/pages/PasswordUpdate';
import WorkflowList from '@/pages/workflow/WorkflowList';
import WorkflowEditor from '@/pages/workflow/WorkflowEditor';
import WorkflowExecute from '@/pages/workflow/WorkflowExecute';
import WorkflowResult from '@/pages/workflow/WorkflowResult';
import ExecuteModel from '@/pages/ExecuteModel';
import ExecutionList from '@/pages/ExecutionList';
import ExecutionView from '@/pages/ExecutionView';
import ManageableModelList from '@/pages/ManageableModelList';
import Portal from '@/pages/Portal';
import JobsList from '@/pages/JobsList';
import ModelRegistration from '@/pages/ModelRegistration';
import ModelUpdate from '@/pages/ModelUpdate';
import ModelTest from '@/pages/ModelTest';
import Menu from '@/components/Menu';

import ProjectList from '@/pages/design/ProjectList';
import ProjectDesignSettings from '@/pages/design/ProjectDesignSettings';
import ProjectDesign from '@/pages/design/ProjectDesign';

import GroupManagement from '@/pages/GroupManagement';

import NotFound from '@/pages/special/NotFound';
import ForbiddenPage from '@/pages/special/Forbidden';
import MaintenancePage from '@/pages/special/Maintenance';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/portal',
      name: 'Portal',
      components: {
        main: Portal
      },
      meta: {
        title: 'Portal'
      }
    },
    {
      path: '/',
      name: 'MLPlatform',
      components: {
        menu: Menu,
        main: MLPlatform
      },
      meta: {
        title: 'Dashboard',
        manualPage: 2
      }
    },
    {
      path: '/login',
      name: 'Auth',
      components: {
        main: Auth
      },
      meta: {
        title: 'Login'
      }
    },
    {
      path: '/password-update',
      name: 'PasswordUpdate',
      components: {
        menu: Menu,
        main: PasswordUpdate
      },
      meta: {
        title: 'パスワード変更'
      }
    },
    {
      path: '/ai',
      name: 'MLList',
      components: {
        menu: Menu,
        main: MLList
      },
      meta: {
        title: 'List of AI models',
        manualPage: 1
      }
    },
    {
      path: '/workflows',
      name: 'WorkflowList',
      components: {
        menu: Menu,
        main: WorkflowList
      },
      meta: {
        title: 'List of Workflows',
        manualPage: 1
      }
    },
    {
      path: '/workflow/:id?',
      name: 'WorkflowEditor',
      components: {
        menu: Menu,
        main: WorkflowEditor
      },
      meta: {
        title: 'Workflow Editor',
        manualPage: 1
      }
    },
    {
      path: '/workflow/execution/:id',
      name: 'WorkflowResult',
      components: {
        menu: Menu,
        main: WorkflowResult
      },
      meta: {
        title: 'Workflow Execution Results',
        manualPage: 1
      }
    },
    {
      path: '/workflow/execute/:id',
      name: 'WorkflowExecution',
      components: {
        menu: Menu,
        main: WorkflowExecute
      },
      meta: {
        title: 'Workflow Execution',
        manualPage: 1
      }
    },
    {
      path: '/execute/:id',
      name: 'ExecuteModel',
      components: {
        menu: Menu,
        main: ExecuteModel
      },
      meta: {
        title: 'Model Execution',
        manualPage: 1
      }
    },
    {
      path: '/executions',
      name: 'ExecutionList',
      components: {
        menu: Menu,
        main: ExecutionList
      },
      meta: {
        title: 'List of Executions',
        manualPage: 1
      }
    },
    {
      path: '/executions/:id',
      name: 'ExecutionView',
      components: {
        menu: Menu,
        main: ExecutionView
      },
      meta: {
        title: 'Execution Results',
        manualPage: 1
      }
    },
    {
      path: '/models',
      name: 'UserModelList',
      components: {
        menu: Menu,
        main: ManageableModelList
      },
      meta: {
        title: 'My AI models',
        manualPage: 1
      }
    },
    {
      path: '/jobs',
      name: 'JobsList',
      components: {
        menu: Menu,
        main: JobsList
      },
      meta: {
        title: 'My jobs',
        manualPage: 1
      }
    },
    {
      path: '/models/registration',
      name: 'ModelRegistration',
      components: {
        menu: Menu,
        main: ModelRegistration
      },
      meta: {
        title: 'Model Registration',
        manualPage: 1
      }
    },
    {
      path: '/model/:id',
      name: 'ModelUpdate',
      components: {
        menu: Menu,
        main: ModelUpdate
      },
      meta: {
        title: 'Model Update',
        manualPage: 1
      }
    },
    {
      path: '/models/:id/test',
      name: 'ModelTest',
      components: {
        menu: Menu,
        main: ModelTest
      },
      meta: {
        title: 'Model Testing',
        manualPage: 1
      }
    },
    {
      path: '/projects/',
      name: 'Projects',
      components: {
        menu: Menu,
        main: ProjectList
      },
      meta: {
        title: 'プロジェクト一覧',
        manualPage: 5
      }
    },
    {
      path: '/project/:projectId/design',
      name: 'ProjectDesignSettings',
      components: {
        menu: Menu,
        main: ProjectDesignSettings
      },
      meta: {
        title: 'デザイン設定',
        manualPage: 8
      }
    },
    {
      path: '/project/design/:id',
      name: 'ProjectDesign',
      components: {
        menu: Menu,
        main: ProjectDesign
      },
      meta: {
        title: 'デザイン情報',
        manualPage: 13
      }
    },
    {
      path: '/groups',
      name: 'GroupManagement',
      components: {
        menu: Menu,
        main: GroupManagement
      },
      meta: {
        title: 'グループ管理',
        manualPage: 1
      }
    },
    {
      path: '/model-management',
      name: 'StaffModelList',
      components: {
        menu: Menu,
        main: ManageableModelList
      },
      meta: {
        title: 'モデル管理',
        manualPage: 1
      }
    },
    // {
    //     path: '/design/compare',
    //     name: 'DesignCompareView',
    //     components: {
    //         menu: DesignMenu,
    //         main: DesignCompareView
    //     },
    //     meta: {
    //         title: ''
    //     }
    // },
    // {
    //     path: '/design/:id',
    //     name: 'DesignResultView',
    //     components: {
    //         menu: DesignMenu,
    //         main: DesignResultView
    //     },
    //     meta: {
    //         title: ''
    //     }
    // }
    {
      path: '*',
      name: 'NotFound',
      components: {
        menu: Menu,
        main: NotFound
      },
      meta: {
        title: 'Page not found',
        manualPage: 1
      }
    },
    {
      path: '*',
      name: 'Forbidden',
      components: {
        menu: Menu,
        main: ForbiddenPage
      },
      meta: {
        title: 'Access denied',
        manualPage: 1
      }
    },
    {
      path: '*',
      name: 'Maintenance',
      components: {
        main: MaintenancePage
      },
      meta: {
        title: 'Maintenance',
        manualPage: 1
      }
    }

  ]
});

router.beforeEach((toRoute, fromRoute, next) => {
  if (MAINTENANCE_MODE && toRoute.name !== 'Maintenance') {
    router.push({ name: 'Maintenance' });
  } else {
    next();
  }
});

router.beforeEach((toRoute, fromRoute, next) => {
  const session = Vue.prototype.$session;
  session.start();
  const tokenUsageTime = (Date.now() - session.get('tokenInitTime')) / 1000;
  if (tokenUsageTime > TOKEN_EXPIRED_TIME) {
    session.destroy();
  }
  if (tokenUsageTime > REFRESH_TOKEN_TIME &&
      tokenUsageTime < TOKEN_EXPIRED_TIME) {
    Vue.api.refreshToken(session.get('token'), function(res) {
      session.set('token', res.data.token);
      session.set('tokenInitTime', Date.now());
    }, function() {
      session.destroy();
    });
  }
  next();
});

router.beforeEach((toRoute, fromRoute, next) => {
  if (toRoute.meta && toRoute.meta.title) {
    window.document.title = 'DAIIA Platform - ' + toRoute.meta.title;
  } else {
    window.document.title = 'DAIIA Platform';
  }
  next();
});

router.beforeEach((toRoute, fromRoute, next) => {
  const session = Vue.prototype.$session;
  session.start();
  const currentUser = session.get('user');
  if (toRoute.name === 'GroupManagement' &&
    currentUser &&
    currentUser.owned_groups.length === 0) {
    router.push({ name: 'Forbidden' });
  }
  if (toRoute.name === 'StaffModelList' &&
    currentUser &&
    !currentUser.is_staff) {
    router.push({ name: 'Forbidden' });
  }
  next();
});

export default router;
