<template>
  <v-container>
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
    />

    <ModelRegistrationForm ref="child"
                           :parent-module="mlModule"
                           :is-version-up="isVersionUp"
                           @formValidated="formValidated"
                           @outputChanged="outputChanged"
                           @showSimpleTest="switchTestDisplay"
    />
    <template v-if="showSimpleTest">
      <v-card>
        <v-card-title>
          Single test information
          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <sup>
                <v-icon dense small v-bind="attrs" v-on="on">
                  mdi-help-circle-outline
                </v-icon>
              </sup>
            </template>
            <span>
              必須項目のみの場合、モデルの登録後にテストを実行する。<br>
              テストのための入力と期待するテスト結果を指定する。<br>
              テスト実行の際に使われる値は、設定したデフォルト値が利用される。
            </span>
          </v-tooltip>
        </v-card-title>
        <v-card-text>
          <v-row no-gutters>
            <v-col cols="3">
              <v-subheader class="pt-3 mt-1">
                compounds_file
                <v-tooltip top>
                  <template #activator="{ on, attrs }">
                    <sup>
                      <v-icon dense small v-bind="attrs" v-on="on">
                        mdi-help-circle-outline
                      </v-icon>
                    </sup>
                  </template>
                  <span>
                    テストの入力ファイル
                  </span>
                </v-tooltip>
              </v-subheader>
            </v-col>
            <v-col cols="7">
              <v-file-input
                v-model="compoundsFile"
                label="Compounds file"
                show-size
                :rules="fileRules"
              />
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="3">
              <v-subheader class="pt-3 mt-1">
                {{ outputs[0].value }}
                <v-tooltip top>
                  <template #activator="{ on, attrs }">
                    <sup>
                      <v-icon dense small v-bind="attrs" v-on="on">
                        mdi-help-circle-outline
                      </v-icon>
                    </sup>
                  </template>
                  <span>
                    テストで期待される出力ファイル
                  </span>
                </v-tooltip>
              </v-subheader>
            </v-col>
            <v-col cols="7">
              <v-file-input
                v-model="predictedFile"
                label="Predicted file"
                show-size
                :rules="fileRules"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </template>
    <v-btn color="primary" text @click="onSubmit">
      Create Model
    </v-btn>
  </v-container>
</template>

<script>
import Loading from 'vue-loading-overlay';
import router from '@/router';
import ModelRegistrationForm from '@/components/ModelRegistrationForm';
import { showSuccessDialog, showErrorDialog } from '@/mixins/utils';
import consts from '@/store/consts';

export default {
  components: {
    ModelRegistrationForm,
    loading: Loading
  },
  data() {
    return {
      outputs: [{ value: '' }],
      compoundsFile: null,
      predictedFile: null,
      mlModule: null,
      showSimpleTest: false,
      isLoading: false,
      isVersionUp: false,
      baseModuleId: null,
      fileRules: [
        v => (v === null || v.size > 0) || 'File is empty'
      ],
      callBackPageName: this.$session.get('callBackPageName', 'UserModelList')
    };
  },
  mounted() {
    this.checkLoggedIn(this.$session);
    this.baseModuleId = this.$route.query.base_model_id;
    if (this.baseModuleId) {
      const self = this;
      this.api.getMLModuleWithVersionInfo(
        self.baseModuleId,
        function(module) {
          if (module.status === 'Verified') {
            self.mlModule = module;
            delete self.mlModule.id;
            self.mlModule.name = `${module.root_model_name} v${module.next_version}`;
            self.isVersionUp = true;
          }
        },
        function() {
          // TODO: Add a feedback error message different
          // for 404 and other unexpected errors?
        }
      );
    }
  },
  methods: {
    switchTestDisplay(show) {
      this.showSimpleTest = show;
    },
    outputChanged(outputs) {
      this.outputs = outputs;
    },
    onSubmit() {
      this.$refs.child.validateForm();
    },
    formValidated(mlModule, files) {
      this.sanitizeModel(mlModule);
      mlModule.baseModuleId = this.baseModuleId;
      if (this.compoundsFile !== null && this.predictedFile !== null) {
        files.compounds_file = this.compoundsFile;
        files[this.outputs[0].value] = this.predictedFile;
      }
      const self = this;
      self.isLoading = true;
      this.api.registerAIModel(
        mlModule,
        files,
        function(response) {
          self.isLoading = false;
          showSuccessDialog(
            'Your new AI model will be ready soon.',
            'You have been redirected to your list of models.'
          );
          router.push({ name: self.callBackPageName });
        },
        function(error) {
          self.isLoading = false;
          if (error.status === 400) {
            showErrorDialog('Something went wrong.', error.data);
          } else {
            showErrorDialog(
              'Something went wrong.',
              'Try again, or contact an administrator.'
            );
          }
        }
      );
    },
    sanitizeModel(mlModule) {
      if (mlModule && !consts.PredAndUncertainTypes.includes(mlModule?.type)) {
        for (const attr of consts.PredictionModelFields) {
          delete mlModule[attr];
        }
      }
      // Clean unnecessary fields for each model type
      if (mlModule && mlModule?.type) {
        for (const field of consts.UnusedFieldsByModelType[mlModule.type]) {
          delete mlModule[field];
        }
      }
    }
  }
};
</script>
