import { render, staticRenderFns } from "./PortalCard.vue?vue&type=template&id=761de1fa&scoped=true&"
import script from "./PortalCard.vue?vue&type=script&lang=js&"
export * from "./PortalCard.vue?vue&type=script&lang=js&"
import style0 from "./PortalCard.vue?vue&type=style&index=0&id=761de1fa&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "761de1fa",
  null
  
)

export default component.exports