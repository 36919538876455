<template>
  <v-card class="text-center" :to="to" :color="color" :height="height">
    <v-card-text align :class="contentPosition">
      <v-icon :size="iconSize">{{ icon }}</v-icon>
    </v-card-text>
    <v-card-title class="pt-0 justify-center" :style="`font-size: ${fontSize}`">
      {{ text }}
    </v-card-title>
  </v-card>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    },
    to: {
      type: Object,
      required: true
    },
    color: {
      type: String,
      default: ''
    },
    isBig: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    height: function() {
      return this.isBig ? 300 : 150;
    },
    contentPosition: function() {
      return this.isBig ? 'pt-16' : '';
    },
    fontSize: function() {
      const coef = this.isBig ? 2.0 : 1.0;
      const baseSize = ['xl', 'lg'].includes(this.$vuetify.breakpoint.name)
        ? 1.10
        : 1;
      return baseSize * coef + 'rem';
    },
    iconSize: function() {
      const coef = this.isBig ? 2.0 : 1.0;
      return `${55 * coef}`;
    }
  }
};
</script>
