import Vue from 'vue';
import Vuex from 'vuex';

import error from './modules/error';
import title from './modules/title';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    error,
    title
  }
});

export default store;
