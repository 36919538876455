<template>
  <v-container>
    <h2>Project</h2>
    <v-divider />
    <v-row class="mb-5">
      <v-col :cols="bigCardColumns">
        <DashboardCard
          icon="mdi-format-list-text"
          text="Open projects"
          :to="{ name: 'Projects' }"
          color="rgba(191,228,255,0.5)"
          :is-big="true"
        />
      </v-col>
    </v-row>

    <h2>AI Model</h2>
    <v-divider />
    <v-row class="mb-5">
      <v-col :cols="cardColumns">
        <DashboardCard
          icon="mdi-head-dots-horizontal-outline"
          text="Use an AI model"
          :to="{ name: 'MLList' }"
          color="rgba(216,242,85,0.5)"
        />
      </v-col>
      <v-col :cols="cardColumns">
        <DashboardCard
          icon="mdi-table"
          text="Check execution results"
          :to="{ name: 'ExecutionList' }"
          color="rgba(255,255,128,0.5)"
        />
      </v-col>
      <v-col :cols="cardColumns">
        <DashboardCard
          icon="mdi-mortar-pestle-plus"
          text="Register a new AI model"
          :to="{ name: 'ModelRegistration' }"
          color="rgba(255,202,191,0.5)"
        />
      </v-col>
    </v-row>

    <h2>Workflow</h2>
    <v-divider />
    <v-row class="mb-5">
      <v-col :cols="cardColumns">
        <DashboardCard
          icon="mdi-stack-overflow"
          text="Use a workflow"
          :to="{ name: 'WorkflowList' }"
          color="rgba(119,217,168,0.5)"
        />
      </v-col>
      <v-col :cols="cardColumns">
        <DashboardCard
          icon="mdi-cogs"
          text="Create a new workflow"
          :to="{ name: 'WorkflowEditor' }"
          color="rgba(201,172,230,0.5)"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DashboardCard from '@/components/DashboardCard';

export default {
  name: 'MLPlatform',
  components: {
    DashboardCard
  },
  computed: {
    cardColumns: function() {
      return this.$vuetify.breakpoint.name === 'xl' ? 2 : 3;
    },
    bigCardColumns: function() {
      return this.$vuetify.breakpoint.name === 'xl' ? 4 : 6;
    }
  },
  mounted() {
    this.checkLoggedIn(this.$session);
  }
};
</script>
