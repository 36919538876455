<template>
  <v-container class="pb-0">
    <MLTable />
  </v-container>
</template>

<script>
import MLTable from '../components/MLTable';

export default {
  name: 'MLList',
  components: {
    MLTable: MLTable
  },
  mounted() {
    this.checkLoggedIn(this.$session);
  }
};
</script>
