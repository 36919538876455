/* eslint-disable vue/multiline-html-element-content-newline */
<template>
  <v-card class="mx-auto mb-2">
    <v-card-title>
      {{ model.name }}
    </v-card-title>
    <v-card-subtitle
      class="text--primary description-block"
    >
      {{ model.description }}
    </v-card-subtitle>
    <v-card-text>
      <v-row v-if="isFeaturePredictionType()" no-gutters>
        <v-col cols="3">Prediction unit: </v-col>
        <v-col cols="9">{{ model.unit }}</v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="3">Documentation: </v-col>
        <v-col cols="9">
          <span
            v-if="model.document_name"
            class="downloadLink"
            @click="downloadDocument"
          >
            {{ model.document_name }}
          </span>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { showErrorDialog } from '@/mixins/utils';
import consts from '@/store/consts';

export default {
  name: 'MLMOverviewDisplay',
  components: {
  },
  props: {
    modelId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      model: {}
    };
  },
  watch: {
    modelId: {
      handler() {
        this.getModule();
      },
      deep: true
    }
  },
  mounted() {
    this.getModule();
  },
  methods: {
    getModule() {
      const self = this;
      if (this.modelId) {
        this.api.getMLModule(this.modelId, function(model) {
          self.model = model;
        }, null, false, true);
      }
    },
    downloadDocument() {
      if (this.model?.document_name) {
        const self = this;
        this.api.downloadDocumentFile(
          self.model.id,
          function(response) {
            self.api.downloadFileHelper(
              response, self.model.document_name);
          },
          function() {
            showErrorDialog(
              'ファイルが見つかりません。',
              '指定されたファイルを見つけることができませんでした。'
            );
          },
          true
        );
      }
    },
    isFeaturePredictionType() {
      const moduleType = this.model.type ? this.model.type.replace(/\s+/g, '') : '';
      return this.model &&
        consts.FeaturePredTypes.includes(moduleType);
    }
  }
};
</script>
