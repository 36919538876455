<template>
  <v-app>
    <v-main>
      <router-view name="menu" />
      <template v-if="displayErrorPage">
        <Error :error-code="errorCode" />
      </template>
      <template v-else>
        <router-view :key="$route.fullPath" name="main" />
      </template>
    </v-main>
  </v-app>
</template>

<script>
import Error from '@/components/Error';
import { mapGetters } from 'vuex';

export default {
  name: 'App',
  components: { Error: Error },
  computed: {
    ...mapGetters('error', {
      displayErrorPage: 'displayPage',
      errorCode: 'code'
    }
    )
  },
  watch: {
    $route(to, from) {
      this.$store.commit('error/clear');
    }
  }
};
</script>

<style lang="scss">
@import '@/styles/common.scss';
</style>
