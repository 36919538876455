<template>
  <v-container class="pb-0">
    <div class="mb-3">
      <v-btn color="primary" @click="createWorkflow">
        Create a new workflow
      </v-btn>
    </div>
    <WorkflowTable />
  </v-container>
</template>

<script>
import router from '../../router';
import WorkflowTable from '@/components/WorkflowTable.vue';

export default {
  name: 'WorkflowList',
  components: {
    WorkflowTable: WorkflowTable
  },
  mounted() {
    this.checkLoggedIn(this.$session);
  },
  methods: {

    createWorkflow() {
      router.push({ name: 'WorkflowEditor', params: {} });
    }
  }
};
</script>
