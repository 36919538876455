<template>
  <v-autocomplete
    v-model="value"
    :items="groupedItems"
    v-bind="$attrs"
    @change="onChange"
  >
    <template #append-outer>
      <slot name="icon" />
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  name: 'GroupedAutocomplete',
  props: {
    items: {
      type: Array,
      required: true
    },
    groupName: {
      type: String,
      required: true
    },
    selectedValue: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      value: ''
    };
  },
  computed: {
    groupedItems() {
      const grouped = {};
      this.items.forEach(item => {
        if (!grouped[item[this.groupName]]) {
          grouped[item[this.groupName]] = [];
        }
        grouped[item[this.groupName]].push(item);
      });

      const result = [];
      const categories = Object.keys(grouped);
      categories.forEach((group, index) => {
        result.push({ header: group });
        grouped[group].forEach(item => {
          result.push(item);
        });
        // Don't add divider after the last group
        if (index !== categories.length - 1) {
          result.push({ divider: true });
        }
      });

      return result;
    }
  },
  watch: {
    selectedValue: {
      handler(value) {
        this.value = value;
      }
    }
  },
  mounted() {
    this.value = this.selectedValue;
  },
  methods: {
    onChange() {
      this.$emit('input', this.value);
      this.$emit('change', this.value);
    }
  }

};
</script>
