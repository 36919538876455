<template>
  <div>
    <p>
      <span>{{ truncatedText }}</span>
      <a
        v-if="!isExpanded && fullText.length > truncateTo"
        @click="isExpanded=true"
      >
        すべて表示
      </a>
      <a
        v-if="isExpanded"
        @click="isExpanded=false"
      >
        折りたたむ
      </a>
    </p>
  </div>
</template>
<script>

export default {
  name: 'TruncatedTextDisplay',
  props: {
    fullText: {
      type: String,
      default: ''
    },
    truncateTo: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      isExpanded: false
    };
  },
  computed: {
    truncatedText() {
      if (this.isExpanded) {
        return this.fullText;
      } else {
        return this.fullText.slice(0, this.truncateTo) +
          (this.fullText.length > this.truncateTo ? '...' : '');
      }
    }
  },
  methods: {
  }
};
</script>
