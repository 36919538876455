<template>
  <v-card class="pa-5">
    <v-card-title>
      List of objective values
    </v-card-title>
    <v-card-text>
      <v-textarea
        v-model="objVals"
        :readonly="readonly"
      />
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn v-show="!readonly" class="mx-2" color="primary" @click="updateValues">Ok</v-btn>
      <v-btn class="mx-2" color="error" @click="cancel">
        {{ readonly ? 'Close' : 'Cancel' }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'ObjectiveValueList',
  props: {
    currValues: {
      type: Array,
      default: () => []
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      objVals: ''
    };
  },
  watch: {
    currValues: {
      handler() {
        this.setOriginalValues();
      },
      deep: true
    }
  },
  async mounted() {
    this.setOriginalValues();
  },
  methods: {
    setOriginalValues() {
      this.objVals = this.currValues.join('\n');
    },
    updateValues() {
      const items = this.objVals.split('\n')
        .map(item => item.trim())
        .filter(item => item !== '');
      this.objVals = items.join('\n');
      this.$emit('update', items);
    },
    cancel() {
      this.setOriginalValues();
      this.$emit('close');
    }
  }
};
</script>
