<template>
  <v-card outlined class="no-border">
    <v-row justify="center" no-gutters>
      <div v-html="design.base.structure" />
    </v-row>
    <v-row no-gutters>
      <div class="pd-dialog-button">
        <ExecutionInputs
          :execution-id="design.execution"
          :execution-type="executionType"
          small
        />
      </div>
      <v-spacer />
      <v-dialog v-if="design.scaffold_structure"
                v-model="dialog"
                width="500"
                hight="500"
      >
        <template #activator="{ on, attrs }">
          <v-btn
            color="red lighten-2"
            class="mb-1"
            dark
            small
            v-bind="attrs"
            v-on="on"
          >
            scaffoldを見る
          </v-btn>
        </template>
        <v-card>
          <div v-html="design.scaffold_structure" />
        </v-card>
      </v-dialog>
    </v-row>
    <v-card-subtitle v-if="design.memo">
      <v-divider />
      {{ design.memo }}
    </v-card-subtitle>
  </v-card>
</template>

<script>
import ExecutionInputs from '@/components/ExecutionInputs';

export default {
  name: 'ProjectDesignCard',
  components: {
    ExecutionInputs
  },
  props: {
    design: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dialog: false,
      executionType: 'projectDesign'
    };
  }
};
</script>
