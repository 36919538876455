<template>
  <v-card class="scroll">
    <v-card-text>
      <table class="table">
        <thead height="240px">
          <tr>
            <th
              v-for="(target, index) in info.targets"
              :key="index"
              class="header"
            >
              <p>{{ target.name }}</p>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td
              v-for="(target, index) in info.targets"
              :key="index"
              class="cell"
            >
              <v-tooltip top>
                <template #activator="{ on }">
                  <div
                    v-bind="$attrs"
                    :class="`content elevation-2 ${target.activity}`"
                    v-on="{ ...on, ...$listeners }"
                  >
                                    &nbsp;
                  </div>
                </template>
                <span>
                  {{ target.predicted == null ? '...' : target.predicted }}
                </span>
              </v-tooltip>
            </td>
          </tr>
        </tbody>
      </table>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'TargetHeatMap',
  props: {
    info: {
      type: Object,
      required: true
    }
  },
  mounted() {
  },
  methods: {
  }
};
</script>

<style scoped>
.table {
    width: 100%;
}
.header {
    vertical-align: bottom;
}
.header > p {
    transform: rotate(270deg);
    white-space: nowrap;
    text-align: left;
    width: 22px;
}
.content {
    width: 22px;
    height: 22px;
    border: 1px solid;
    border-color: black;
    border-radius: 10%;
}
.scroll {
  overflow-x: auto;
}
.scroll {
  overflow-x: auto;
}
</style>
