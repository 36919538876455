<template>
  <div>
    <v-dialog v-model="dialog" width="1200">
      <TargetHeatMap :info="currentGroup.info" />
    </v-dialog>
    <v-row no-gutters>
      <TargetProfileCategory
        v-if="potencyTargets.length > 0"
        category="Potency"
        :nb-rows="potencyTargets.length"
        :nb-columns="1"
        :profile="potencyTargets"
        :show-priority="showPriority"
        :show-category-name="showCategoryName"
        :show-values="showValues"
        :priority-read-only="priorityReadOnly"
        :show-credibility="showCredibility"
        :show-tooltip="showTooltip"
        @openDialog="openDialog"
        @changePriority="changePriority"
      />
      <TargetProfileCategory
        v-if="propertyTargets.length > 0"
        category="Property"
        class="mr-4"
        :nb-rows="nbRows('Property')"
        :nb-columns="2"
        :profile="propertyTargets"
        :show-priority="showPriority"
        :show-category-name="showCategoryName"
        :show-values="showValues"
        :priority-read-only="priorityReadOnly"
        :show-credibility="showCredibility"
        :show-tooltip="showTooltip"
        @openDialog="openDialog"
        @changePriority="changePriority"
      />
      <v-col class="ma-0 pa-0">
        <v-row no-gutters>
          <TargetProfileCategory
            v-if="toxicityTargets.length > 0"
            category="Toxicity"
            :nb-rows="1"
            :nb-columns="toxicityTargets.length"
            :profile="toxicityTargets"
            :show-priority="showPriority"
            :show-category-name="showCategoryName"
            :show-values="showValues"
            :priority-read-only="priorityReadOnly"
            :show-credibility="showCredibility"
            :show-tooltip="showTooltip"
            @openDialog="openDialog"
            @changePriority="changePriority"
          />
        </v-row>
        <v-row no-gutters>
          <TargetProfileCategory
            v-if="admeTargets.length > 0"
            category="ADME"
            :nb-rows="1"
            :nb-columns="admeTargets.length"
            :profile="admeTargets"
            :show-priority="showPriority"
            :show-category-name="showCategoryName"
            :show-values="showValues"
            :priority-read-only="priorityReadOnly"
            :show-credibility="showCredibility"
            :show-tooltip="showTooltip"
            @openDialog="openDialog"
            @changePriority="changePriority"
          />
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { activityCssClass } from '@/mixins/utils';
import TargetHeatMap from '@/components/design/TargetHeatMap';
import TargetProfileCategory from '@/components/design/TargetProfileCategory';

export default {
  name: 'TargetProfileView',
  components: {
    TargetProfileCategory,
    TargetHeatMap
  },
  props: {
    compound: {
      type: Object,
      default: () => null
    },
    profile: {
      type: Array,
      default: () => []
    },
    showPriority: {
      type: Boolean,
      default: false
    },
    showCategoryName: {
      type: Boolean,
      default: false
    },
    showValues: {
      type: Boolean,
      default: true
    },
    priorityReadOnly: {
      type: Boolean,
      default: false
    },
    showCredibility: {
      type: Boolean,
      default: false
    },
    showTooltip: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      categories: ['Potency', 'Toxicity', 'ADME', 'Property'],
      nbColumns: 2,
      dialog: false,
      currentGroup: {}
    };
  },
  computed: {
    potencyTargets: function() {
      return this.filterTargets('Potency');
    },
    toxicityTargets: function() {
      return this.filterTargets('Toxicity');
    },
    admeTargets: function() {
      return this.filterTargets('ADME');
    },
    propertyTargets: function() {
      return this.filterTargets('Property');
    }
  },
  mounted() {},
  methods: {
    nbRows(category) {
      let items = [];
      const nbColumns = 2;
      if (category === 'Potency') items = this.potencyTargets;
      else if (category === 'Toxicity') items = this.toxicityTargets;
      else if (category === 'ADME') items = this.admeTargets;
      else if (category === 'Property') items = this.propertyTargets;
      return items ? Math.ceil(items.length / nbColumns) : 0;
    },
    filterTargets(category) {
      const filteredItems = [];
      const items = this.profile.filter(
        t => t.category === category
      );
      for (const item of items) {
        const newItem = { ...item };
        if (this.compound && this.compound !== {}) {
          if (newItem.initialName && `${category}:${newItem.initialName}` in this.compound) {
            newItem.initial = this.compound[`${category}:${newItem.initialName}`].initial;
            newItem.predicted = this.compound[`${category}:${newItem.initialName}`].predicted;
            newItem.credibility = this.compound[`${category}:${newItem.initialName}`].credibility;
            newItem.status = this.compound[`${category}:${newItem.initialName}`].status;
            newItem.activity = this.activity(
              newItem,
              this.compound[`${category}:${newItem.initialName}`]
            );
          } else if (newItem.predictionName && `${category}:${newItem.predictionName}` in this.compound) {
            newItem.predicted = this.compound[`${category}:${newItem.predictionName}`].predicted;
            newItem.credibility = this.compound[`${category}:${newItem.predictionName}`].credibility;
            newItem.status = this.compound[`${category}:${newItem.predictionName}`].status;
            if (newItem.predictionType === 'MultipleFeaturePrediction') {
              newItem.isGroup = true;
              newItem.info = this.generateTargetGroupData(newItem, this.compound[`${category}:${newItem.predictionName}`]);
              newItem.count = {};
            } else {
              newItem.activity = this.activity(
                newItem,
                this.compound[`${category}:${newItem.predictionName}`]
              );
            }
          }
        }
        filteredItems.push(newItem);
      }
      return filteredItems;
    },
    activity(profile, compound) {
      const value = compound.initial ?? compound.predicted;
      if (value == null) {
        return '';
      } else {
        return activityCssClass(value, profile);
      }
    },
    generateTargetGroupData(profileItem, groupData) {
      const predictedValue = groupData.predicted ?? {};
      const activities = ['profile-inactive'];
      const targets = Object.keys(predictedValue).map((name) => ({
        name: name,
        predicted: predictedValue[name]
        // credibility: profileItem.credibility,
      }));
      for (const target of targets) {
        target.activity = activityCssClass(target.predicted, profileItem);
      }
      const count = activities.reduce((acc, n) => {
        acc[n] = 0;
        return acc;
      }, {});
      targets.forEach((e) => {
        if (e.activity in count) {
          count[e.activity] += 1;
        }
      });
      return {
        count: count,
        targets: targets
      };
    },
    openDialog(targetGroup) {
      this.currentGroup = targetGroup;
      this.dialog = true;
    },
    changePriority(item, priority) {
      this.$emit('changePriority', item, priority);
    }
  }
};
</script>

<style scoped>
.clickable {
    cursor: pointer;
}
.colorBox {
    width: 10px;
    height: 10px;
    border: 1px solid;
    display: inline-block;
}
</style>
