<template>
  <v-container>
    <v-row
      v-for="design of projectDesigns"
      :key="design.id"
    >
      <v-col>
        <v-card
          :class="{
            'elevation-2': !design.selected,
            'selected': design.selected
          }"
          outlined
          @click.native="onSelectDesign(design)"
        >
          <v-row>
            <v-col cols="3">
              <ProjectDesignCard
                v-if="design.base"
                :design="design"
              />
            </v-col>
            <v-col>
              <TargetProfileView
                :profile="design.targets"
                show-category-name
                :show-values="false"
                :show-priority="design.base != null"
                priority-read-only
              />
            </v-col>
          </v-row>
          <v-card-actions>
            <div class="text-overline mb-4">
              最終更新 {{ design.updated_at }}
            </div>
            <v-spacer />
            <v-btn color="primary" :to="onClickDetails(design)">
              <span v-if="design.base == null">設定...</span>
              <span v-else>予測結果を見る</span>
            </v-btn>
            <v-btn
              v-if="currentUser.username === design.created_by"
              color="error"
              text
              @click="onClickDelete(design)"
            >
              <span>削除</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ProjectDesignCard from '@/components/design/Project/ProjectDesignCard';
import TargetProfileView from '@/components/design/TargetProfileView';
import {
  showConfirmDialog,
  showSuccessDialog,
  showErrorDialog
} from '@/mixins/utils';

export default {
  name: 'ProjectDesignList',
  components: {
    ProjectDesignCard,
    TargetProfileView
  },
  props: {
    project: {
      type: Object,
      required: true
    },
    modules: {
      type: Object,
      required: true
    },
    selectedDesigns: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      projectDesigns: [],
      currentUser: this.$session.get('user')
    };
  },
  mounted() {
    this.loadProjectDesigns();
  },
  methods: {
    loadProjectDesigns() {
      const self = this;
      const config = {
        params: { project_id: this.project.id }
      };
      this.api.loadProjectDesigns(config,
        function(list) {
          self.projectDesigns = list.map(pd => {
            return { ...pd, selected: false };
          });
        }, function(error) {
          if (error.response.status !== 401) {
            console.log(error.response.data);
          }
        });
    },
    onClickDetails(projectDesign) {
      return {
        name: 'ProjectDesign',
        params: { id: projectDesign.id }
      };
    },
    onSelectDesign(design) {
      if (design.base === null) {
        return null;
      }
      if (this.selectedDesigns.length === 5) {
        if (this.selectedDesigns.find(d => d.id === design.id)) {
          design.selected = !design.selected;
          this.$emit('clickDesign', this.project.id, design);
        }
      } else if (this.selectedDesigns.length < 5) {
        design.selected = !design.selected;
        this.$emit('clickDesign', this.project.id, design);
      }
    },
    onClickDelete(projectDesign) {
      const self = this;
      showConfirmDialog({
        title: 'デサインを削除しようとしています。',
        html: 'この操作は取り消せません。本当に削除しますか?',
        approveCB: () => {
          self.api.deleteProjectDesign(projectDesign.id,
            function() {
              showSuccessDialog('デザインは削除されました。');
              self.$emit('deleteDesign', self.project.id, projectDesign.id);
              self.loadProjectDesigns();
            }, function() {
              showErrorDialog(
                '予想外のエラーが発生しました。',
                'もう一度実行するか、解決しない場合は管理者に知らせてください。'
              );
            });
        }
      });
    }
  }
};
</script>

<style scoped>
.selected {
    border-color: orange;
}
:deep(.no-border) {
  border: none !important;
}
</style>
