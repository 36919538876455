<!-- eslint-disable vue/no-v-html -->
<template>
  <v-container>
    <v-row class="no-gutters">
      <v-col cols="3" class="pa-0">
        <v-select
          v-model="selected"
          hide-details
          :items="headers"
          label="比較項目"
          @change="changeGraph"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4" class="mt-6">
        <v-row
          v-for="design in designs"
          :key="design.id"
          class="pt-1"
          align="center"
        >
          <v-col cols="6" class="py-0">
            <span class="mb-0">
              {{ design.memo }}
            </span>
          </v-col>
          <v-col cols="6" class="py-0">
            <v-card class="mb-0" :to="designsHref(design.id)" target="_blank">
              <div align="center" v-html="design.base.structure" />
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="8">
        <div v-html="graph" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: 'DesignCompareView',
  components: {},
  props: {
    designs: {
      type: Array,
      required: true
    },
    step: {
      type: Number,
      required: true
    },
    refreshResults: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      compareList: {},
      headers: [],
      selected: null,
      graph: null
    };
  },
  watch: {
    step: {
      handler: function() {
        if (this.step === 2) {
          this.headers = [];
          this.compareList = {};
          this.comparableHeaders();
        } else {
          this.selected = null;
          this.graph = null;
        }
      }
    }
  },
  mounted() {
    this.comparableHeaders();
  },
  methods: {
    designsHref(designId) {
      return {
        name: 'ProjectDesign',
        params: { id: designId }
      };
    },
    comparableHeaders() {
      const self = this;
      const designIds = this.designs.map((d) => d.id);
      this.api.getComparableHeaders(designIds,
        function(data) {
          self.headers = data.comparable_headers;
        });
    },
    changeGraph() {
      if (this.selected in this.compareList) {
        this.graph = this.compareList[this.selected];
      } else {
        const self = this;
        const designIds = this.designs.map((d) => d.id);
        this.api.compareDesigns(designIds, self.selected,
          function(data) {
            Object.assign(self.compareList, data.boxplot);
            self.graph = self.compareList[self.selected];
          });
      }
    }
  }
};
</script>
