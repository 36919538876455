<template>
  <v-container>
    <v-row class="ma-5 mt-2">
      <v-spacer />
      <v-btn v-if="step > 1" @click="step -= 1">
        <v-icon dark left> mdi-arrow-left </v-icon>
        <span v-if="step == 2"> プロファイル設定に戻る </span>
        <span v-if="step == 3"> 出発化合物選択に戻る </span>
      </v-btn>
      <v-btn
        v-if="step < 3"
        class="ml-5"
        color="primary"
        :disabled="inTransition"
        @click="nextStep"
      >
        <span v-if="step == 1">出発化合物選択へ</span>
        <span v-else>構造発生へ</span>
        <v-icon dark right> mdi-arrow-right </v-icon>
      </v-btn>
      <v-btn
        v-if="step == 3"
        class="ml-5"
        color="warning"
        width="80px"
        :disabled="baseCompound == null"
        @click="validateMoleculeSelection('kekule-generation-settings')"
      >
        実行
      </v-btn>
    </v-row>
    <v-stepper v-model="step">
      <v-stepper-header class="stepper-header">
        <v-stepper-step :complete="step > 1" step="1">
          プロファイル設定
        </v-stepper-step>
        <v-divider />
        <v-stepper-step :complete="step > 2" step="2">
          出発化合物選択
        </v-stepper-step>
        <v-divider />
        <v-stepper-step step="3">構造発生</v-stepper-step>
      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content step="1">
          <v-row>
            <v-col cols="4" class="py-0">
              <ModuleInput
                name="input_sdf"
                :info="{
                  type: 'file',
                  description: 'SDFファイルを選択',
                }"
                accept=".sdf"
                dense
                language="ja"
                @setFile="onSetFile"
                @resetState="resetState"
              />
            </v-col>
            <v-col cols="2" class="button-pt-5px">
              <ScalingTypeDialog />
            </v-col>
            <v-col cols="6">
              <v-row justify="end" align="center">
                <v-dialog
                  v-if="targets.length > 0"
                  v-model="presetDialog"
                  width="500"
                >
                  <v-card>
                    <v-list class="pt-0">
                      <v-subheader class="sticky-top">プリセット</v-subheader>
                      <v-list-item-group
                        v-model="selectedPreset"
                        color="primary"
                      >
                        <v-list-item
                          v-for="(preset, i) in presets"
                          :key="i"
                        >
                          <v-list-item-content>
                            <v-list-item-title v-text="preset.name" />
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                    <v-divider class="preset-footer-divider" />
                    <v-card-actions class="preset-card-action">
                      <v-spacer />
                      <v-btn
                        v-if="presetDialogStatus == 'Importing'"
                        color="primary"
                        text
                        :disabled="selectedPreset == null"
                        @click="setProfileFromPreset"
                      >
                        読込
                      </v-btn>
                      <v-btn
                        v-else-if="presetDialogStatus == 'Deleting'"
                        class="white--text"
                        color="error"
                        :disabled="selectedPreset == null"
                        @click="deleteProfilePreset"
                      >
                        削除
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <div class="px-2 button-pt-5px">
                  <v-tooltip top>
                    <template #activator="{ on, attrs }">
                      <v-btn
                        color="green lighten-2"
                        dark
                        v-bind="attrs"
                        v-on="on"
                        @click="showPresetDialog('Importing')"
                      >
                        <v-icon dark left>mdi-page-next-outline</v-icon>
                        <span>プリセット読込</span>
                      </v-btn>
                    </template>
                    <span>登録済みのプリセットを選び、プロファイル設定を一括で設定する。</span>
                  </v-tooltip>
                </div>
                <div class="px-2 button-pt-5px">
                  <v-tooltip top>
                    <template #activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        @click="registerPreset"
                      >
                        <v-icon dark left>mdi-card-plus</v-icon>
                        <span>プリセット登録</span>
                      </v-btn>
                    </template>
                    <span>現在入力中のプロファイル設定を、任意の名称で登録する。</span>
                  </v-tooltip>
                </div>
                <div class="px-2 button-pt-5px">
                  <v-tooltip top>
                    <template #activator="{ on, attrs }">
                      <v-btn
                        color="red lighten-2"
                        dark
                        v-bind="attrs"
                        v-on="on"
                        @click="showPresetDialog('Deleting')"
                      >
                        <v-icon dark left>mdi-page-next-outline</v-icon>
                        <span>プリセット削除</span>
                      </v-btn>
                    </template>
                    <span>プリセット一覧からプリセットを削除する。</span>
                  </v-tooltip>
                </div>
              </v-row>
            </v-col>
          </v-row>
          <v-form ref="profileForm" @submit.prevent>
            <ProfileSettings
              ref="profileSetting"
              :targets="targets"
              :target-names="allTargetNames"
              :modules="predictionModules"
              :used-module-ids="usedModuleIds"
              :scaling-types="scalingTypes"
              @addTarget="addTarget"
              @deleteTarget="deleteTarget"
              @updateProfileInput="removeOSInputFromRewardSetting"
            />
          </v-form>
        </v-stepper-content>

        <v-stepper-content step="2">
          <CompoundList
            ref="CompoundList"
            :base-compound="baseCompound"
            :compound-list="compoundList"
            :design-id="designId"
            :profile="targets"
            @onBaseChanged="changeBase"
            @onReferencesSelected="onReferencesSelected"
            @refresh="loadDesignProfile"
          />
        </v-stepper-content>

        <v-stepper-content step="3">
          <GenerationSettings
            ref="genSettings"
            :gen-setting-prop="preGenSetting"
            :compound="baseCompound"
            :profile="targets"
            :reward-setting-value="rewardSettingValue"
            :modules="generationModules"
            :original-filters="filterModules"
            @changePriority="changePriority"
            @repredict="repredictRedrawnCompound"
            @selectSgModule="onSelectSGModule"
          />
          <v-dialog
            v-model="dialog"
            width="500"
          >
            <MemoDialog
              :title="'このデザインについてメモを書きますか。'"
              :cancel-button-label="'戻る'"
              :submit-button-label="'実行'"
              :placeholder="'hA2A、hA1、hERGの両立の優先度高めで'"
              @cancel="dialog=false"
              @confirm="generateStructure"
            />
          </v-dialog>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-container>
</template>

<script>
import router from '@/router';
import { Kekule } from 'kekule';
import CompoundList from '@/components/design/Project/CompoundList';
import MemoDialog from '@/components/MemoDialog';
import GenerationSettings from '@/components/design/Project/GenerationSettings';
import ModuleInput from '@/components/ModuleInput';
import ProfileSettings from '@/components/design/Project/ProfileSettings';
import ScalingTypeDialog from '@/components/design/Project/ScalingTypeDialog';
import {
  isEmpty, showSuccessDialog, showErrorDialog, showConfirmDialog,
  snakeCaseParameters, clone, getSmilesListForEachAsteriskFromEditor
} from '@/mixins/utils';
import { notifyIfJobQueueFull } from '@/mixins/api_utils';
import consts from '@/store/consts';
import Swal from 'sweetalert2';

export default {
  name: 'ProjectDesignSettings',
  components: {
    CompoundList,
    MemoDialog,
    GenerationSettings,
    ModuleInput,
    ProfileSettings,
    ScalingTypeDialog
  },
  props: {
    projectIdProp: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      dialog: false,
      presetDialog: false,
      presetDialogStatus: '',
      projects: [],
      predictionModules: [],
      generationModules: [],
      filterModules: [],
      targets: [],
      allTargetNames: [],
      cachedTargets: [],
      designId: Number(this.$route.params.id),
      projectId: this.projectIdProp || Number(this.$route.params.projectId),
      updateDesign: this.$route.params.id == null,
      baseCompound: null,
      compoundList: [],
      files: {},
      step: 1,
      savedSDF: null,
      memo: null,
      presets: [],
      selectedPreset: null,
      inTransition: false,
      bondToKeep: null,
      atomToReplace: null,
      nbAsterisk: 0,
      maxPresetNameLength: 232,
      scalingTypes: [],
      selectedSGModule: null, // Selected Structure Generation Module at Step 3
      isCorrectDrawnCompound: false,
      referenceCompounds: [],
      rewardSettingValue: null,
      isCreatingChild: this.$route.params.child || false,
      preGenSetting: null
    };
  },
  computed: {
    finalBaseCompoundId: function() {
      return this.baseCompound?.id;
    },
    presetNames: function() {
      return this.presets.map(preset => preset.name.trim());
    },
    usedModuleIds: function() {
      return this.targets.map(t => t.predictionModel).filter(t => t);
    }
  },
  beforeMount() {
    this.api.loadProject(this.projectId);
  },
  mounted() {
    this.checkLoggedIn(this.$session);
    this.loadModels();
    if (this.designId) {
      this.loadDesignProfile();
    }
    this.loadPresets();
    this.loadScalingTypes();
    for (const c of ['Potency', 'Toxicity', 'ADME', 'Property']) {
      this.addTarget(c);
    }
    if (this.isCreatingChild) {
      this.step = 3;
    }
  },
  methods: {
    loadModels() {
      const self = this;
      this.api.getMLModules(
        function(modules) {
          for (const module of modules) {
            if (module.type === 'StructureGeneration') {
              module.config = JSON.parse(module.config);
              self.generationModules.push(module);
            } else if (module.type === 'StructureFilter') {
              self.filterModules.push(module);
            } else if (consts.FeaturePredTypes.includes(module.type)) {
              self.predictionModules.push(module);
            }
          }
          self.predictionModules.sort((a, b) =>
            (a.feature_display?.toLowerCase() >
             b.feature_display?.toLowerCase())
              ? 1
              : -1);
        },
        function(error) {
          console.log(error);
        }
      );
    },
    async onSetFile(...args) {
      const [name, file] = args;
      this.files[name] = file;
      if (file) {
        await this.loadCompounds();
        this.updateDesign = true;
      }
    },
    async loadCompounds(fromEditor = false) {
      this.isLoading = true;
      const self = this;
      await this.api.loadCompoundsFromFile(
        self.files,
        function(data) {
          if (!fromEditor) {
            self.resetState();
          }
          self.baseCompound = null;
          self.resetComposer('kekule-generation-settings');
          self.$refs.CompoundList.base = null;
          self.compoundList = data.compounds;
          self.savedSDF = data.input_sdf;
          self.targetNamesFromCompounds(data.compounds);
        },
        function(error) {
          console.log(error);
        }
      );
    },
    async loadDesignProfile() {
      this.isLoading = true;
      const self = this;
      await this.api.loadDesignProfile(
        this.designId,
        function(data) {
          self.resetState();
          if (data.compounds) {
            self.compoundList = data.compounds;
            self.targets = data.targets;
            self.allTargetNames = data.original_targets;
            self.allTargetNames.unshift('');
          }
          if (self.compoundList.length === 1) {
            self.changeBase(self.compoundList[0]);
          }
          if (data.parent_design) {
            self.loadParentDesignSettings(data.parent_design.id);
          }
        },
        function(error) {
          console.log(error);
        }
      );
    },
    loadPresets() {
      const self = this;
      this.api.loadPresets(
        function(presets) {
          self.presets = presets;
        },
        function(error) {
          console.log(error);
        }
      );
    },
    setProfileFromPreset() {
      this.targets = clone(this.presets[this.selectedPreset].profiles);
      this.presetDialog = false;
      this.selectedPreset = null;
    },
    deleteProfilePreset() {
      const self = this;
      showConfirmDialog({
        title: `${self.presets[self.selectedPreset].name}を削除します。`,
        html: 'この操作は取り消せません。<br><br> 本当に削除しますか?',
        approveCB: () => {
          self.api.deleteProfilePreset(
            self.presets[self.selectedPreset].id,
            function() {
              showSuccessDialog('', `${self.presets[self.selectedPreset].name} は削除されました。`);
              self.selectedPreset = null;
              self.loadPresets();
            },
            function(e) {
              showErrorDialog(
                '予想外のエラーが発生しました。',
                'もう一度実行するか、解決しない場合は管理者に知らせてください。'
              );
            }
          );
        }
      });
    },
    showPresetDialog(status) {
      if (this.presetDialogStatus !== status) {
        this.selectedPreset = null;
        this.presetDialogStatus = status;
      }
      this.presetDialog = true;
    },
    loadScalingTypes() {
      const self = this;
      this.api.loadScalingTypes(
        function(types) {
          self.scalingTypes = types;
        },
        function() {
          self.scalingTypes = [
            { 'value': '', 'label': 'Failed to load data.' }
          ];
        }
      );
    },
    readCompoundFromEditor(kekuleId) {
      const composer = Kekule.Widget.getWidgetOnElem(
        document.getElementById(kekuleId)
      );
      const mol = Kekule.IO.saveFormatData(
        composer.getChemObj(),
        'mol'
      );
      const smiles = Kekule.IO.saveFormatData(
        composer.getChemObj(),
        'smi'
      );
      return { 'smiles': smiles, 'mol': mol };
    },
    validateMoleculeSelection(kekuleId) {
      const composer = Kekule.Widget.getWidgetOnElem(
        document.getElementById(kekuleId)
      );

      const mols = composer.exportObjs(Kekule.Molecule);
      if (mols.length === 0) {
        if (kekuleId === 'kekule-profile-settings') {
          this.isCorrectDrawnCompound = true;
        } else if (kekuleId === 'kekule-generation-settings') {
          this.dialog = true;
        }
        return;
      }
      if (mols.length !== 1) {
        showErrorDialog('描画可能な化合物は単一分子のみです。');
        if (kekuleId === 'kekule-profile-settings') {
          this.isCorrectDrawnCompound = false;
        }
        return;
      }

      // If one or more asterisks are already present,
      // Ignore current selection
      this.nbAsterisk = 0;
      let atomToReplace = null;
      if (kekuleId === 'kekule-profile-settings') {
        this.isCorrectDrawnCompound = true;
      } else if (kekuleId === 'kekule-generation-settings') {
        for (const node of mols[0].getNodes()) {
          if (node.CLASS_NAME === 'Kekule.Pseudoatom') {
            if (node.getSymbol() === '*') {
              this.nbAsterisk += 1;
              atomToReplace = node;
              if (atomToReplace.getLinkedBonds().length > 1) {
                showErrorDialog('現在のアステリスクの位置では構造発生はできません。');
                return;
              }
            }
          }
        }
        if (this.nbAsterisk > 0) {
          const allowedExtPts = this.selectedSGModule?.number_of_ext_pts;
          if (allowedExtPts === 'ExactlyOne' && this.nbAsterisk > 1) {
            showErrorDialog('選択された構造発生モデルではアステリスクは1つのみ許可されています。');
            return;
          }
          this.dialog = true;
          return;
        }

        // No asterisk, in the molecule. Check selection
        const selObjs = composer.getSelection();
        if (selObjs.length === 0) {
          showErrorDialog('構造を変換したい部分を選択してください。');
          return;
        }
        if (!this.$refs.genSettings.validate()) {
          showErrorDialog('構造発生の設定に不備があります', '詳細設定を確認してください。');
          return;
        }
        let nbConnectedNotSelected = 0;
        let bondToKeep = null;
        for (let i = 0; i < selObjs.length; ++i) {
          if (nbConnectedNotSelected > 1) {
            break;
          }
          if (selObjs[i].CLASS_NAME === 'Kekule.Bond') {
            const connectedAtoms = selObjs[i].connectedObjs;
            const atom1 = connectedAtoms[0];
            const atom2 = connectedAtoms[1];
            if (!selObjs.includes(atom1)) {
              nbConnectedNotSelected += 1;
              atomToReplace = atom2;
              bondToKeep = selObjs[i];
            }
            if (!selObjs.includes(atom2)) {
              nbConnectedNotSelected += 1;
              atomToReplace = atom1;
              bondToKeep = selObjs[i];
            }
          } else if (selObjs[i].CLASS_NAME === 'Kekule.Atom') {
            for (const bond of selObjs[i].getLinkedBonds()) {
              if (!selObjs.includes(bond)) {
                nbConnectedNotSelected += 1;
                atomToReplace = selObjs[i];
              }
            }
          }
        }
        if (nbConnectedNotSelected > 1) {
          showErrorDialog('現在の選択では構造発生はできません。');
          return;
        }
        this.bondToKeep = bondToKeep;
        this.atomToReplace = atomToReplace;
        this.dialog = true;
      }
    },
    replaceSelectionByAsterisk() {
      const composer = Kekule.Widget.getWidgetOnElem(
        document.getElementById('kekule-generation-settings')
      );

      const mols = composer.exportObjs(Kekule.Molecule);
      if (mols.length === 0) {
        return;
      }
      const selObjs = composer.getSelection();

      const mol = mols[0];
      const bondToKeepId = this.bondToKeep?.id;
      for (let i = 0; i < selObjs.length; ++i) {
        if (selObjs[i].id !== bondToKeepId &&
            selObjs[i].id !== this.atomToReplace.id) {
          mol.removeNode(selObjs[i]);
        }
        if (selObjs[i].id === this.atomToReplace.id) {
          const coords = this.atomToReplace.getCoord2D();
          const pseudoatom = new Kekule.Pseudoatom()
            .setSymbol('*').setCoord2D(coords);
          mol.appendNode(pseudoatom);
          if (this.bondToKeep) {
            const connectedAtoms = this.bondToKeep.connectedObjs;
            for (let i = 0; i < connectedAtoms.length; ++i) {
              if (connectedAtoms[i].id === this.atomToReplace.id) {
                connectedAtoms[i] = pseudoatom;
              }
            }
          } else {
            const bonds = this.atomToReplace.getLinkedBonds();
            for (let i = 0; i < bonds.length; ++i) {
              if (!selObjs.includes(bonds[i])) {
                const connectedAtoms = bonds[i].connectedObjs;
                for (let i = 0; i < connectedAtoms.length; ++i) {
                  if (connectedAtoms[i].id === this.atomToReplace.id) {
                    connectedAtoms[i] = pseudoatom;
                  }
                }
              }
            }
          }
        }
      }
      mol.removeNode(this.atomToReplace);
    },
    generateStructure(memo) {
      this.dialog = false;
      if (this.nbAsterisk === 0) {
        // If there is no asterisk, replace selection with asterisk
        this.replaceSelectionByAsterisk();
      }

      notifyIfJobQueueFull(() => {
        const compoundToRegister = this.readCompoundFromEditor('kekule-generation-settings');
        const [genSettings, sgModelFiles, filterModelFiles] = this.$refs.genSettings.submit();
        // input_smiles will be the list of compounds with asterisk
        genSettings.sg_model_params.input_smiles = getSmilesListForEachAsteriskFromEditor(this.nbAsterisk, 'kekule-generation-settings');
        if (genSettings) {
          this.registerProfile(compoundToRegister, memo, genSettings, sgModelFiles, filterModelFiles);
        }
      });
    },
    async repredictRedrawnCompound() {
      const compoundToRegister = this.readCompoundFromEditor('kekule-generation-settings');
      // If there is more than one molecule, the smiles will contain a dot
      if (
        compoundToRegister.smiles === '' ||
        compoundToRegister.smiles.includes('.')
      ) {
        showErrorDialog('単一分子を描画する必要があります。');
        return;
      }

      const composer = Kekule.Widget.getWidgetOnElem(
        document.getElementById('kekule-generation-settings')
      );
      const mols = composer.exportObjs(Kekule.Molecule);
      for (const node of mols[0].getNodes()) {
        if (node.CLASS_NAME === 'Kekule.Pseudoatom') {
          showErrorDialog('ダミー原子を含めると再予測できません。');
          return;
        }
      }

      compoundToRegister.is_redrawn = true;
      if (this.baseCompound.smiles === compoundToRegister.smiles) {
        Swal.fire({
          icon: 'warning',
          text: 'この化合物のプロファイルはすでに予測されています。'
        });
      } else {
        const isOK = await this.registerProfile(compoundToRegister);
        if (isOK) {
          this.baseCompound.smiles = Kekule.IO.saveFormatData(
            composer.getChemObj(),
            'smi'
          );
          this.baseCompound = this.compoundList[0];
          showSuccessDialog('新しい化合物のプロファイルが再予測されます。しばらくお待ちください。');
        } else {
          showErrorDialog(
            '予想外のエラーが発生しました。',
            'もう一度実行するか、解決しない場合は管理者に知らせてください。'
          );
        }
      }
    },
    targetNamesFromCompounds(compounds) {
      compounds.forEach((compound) => {
        for (const key in compound) {
          if (
            !key.startsWith('CHEMTS_') &&
                        !['mol', 'structure', 'smiles'].includes(key) &&
                        !this.allTargetNames.includes(key)
          ) {
            this.allTargetNames.push(key);
          }
        }
      });
      this.allTargetNames.unshift('');
    },
    changeBase(baseCompound) {
      this.baseCompound = baseCompound;
      this.initComposer();
      this.drawCompound();
    },
    onReferencesSelected(referenceCompounds) {
      this.referenceCompounds = referenceCompounds;
    },
    addTarget(category) {
      this.targets.push({
        initialName: null,
        predictionModel: null,
        category: category,
        invert: false,
        scalingType: 'threshold'
      });
    },
    deleteTarget(category, index) {
      for (let i = 0; i < this.targets.length; ++i) {
        if (this.targets[i].category === category) {
          if (index === 0) {
            this.targets.splice(i, 1);
            break;
          } else {
            index--;
          }
        }
      }
    },
    prepareTargetsRegistration() {
      const targets = [];
      for (const target of this.targets) {
        targets.push(snakeCaseParameters(target));
      }
      return targets;
    },
    async registerProfile(compoundToRegister, memo, genSettings, sgModelFiles, filterModelFiles) {
      let noProblem = false;
      const formValid = this.$refs.profileForm.validate();
      const rangeValid = this.$refs.profileSetting.validateAllRanges();
      if (formValid && rangeValid) {
        const params = {
          design: {
            project_id: this.projectId,
            input_sdf: compoundToRegister ? null : this.savedSDF
          },
          targets: this.prepareTargetsRegistration(),
          memo: memo,
          generation_settings: genSettings
        };
        if (genSettings) {
          params.base_compound = this.baseCompound;
          params.reference_compound_ids = this.referenceCompounds.map(
            compound => compound.id);
          // If submit design for execution
          // remove OS inputs given in Step 1 from targets
          params.targets.forEach(target => {
            delete target.os_parameters;
            delete target.os_files;
          });
        }
        if (compoundToRegister) {
          params.editor_compound = compoundToRegister;
          params.editor_compound.base_mol_block = this.baseCompound.mol;
        }
        const self = this;
        await this.api.registerProfiles(
          params,
          sgModelFiles,
          filterModelFiles,
          this.designId,
          async function(design) {
            if (params.base_compound) {
              showSuccessDialog(
                'デザインの登録が<br>正常に完了しました ',
                '構造発生はまもなく開始されます。終了後、プロジェクト一覧から結果を閲覧することができます'
              );
              router.push({ name: 'Projects' });
            } else {
              // Going from step 1 to step 2
              self.designId = design.id;
              await self.loadDesignProfile();
              self.cachedTargets = JSON.stringify(self.targets);
              self.updateDesign = false;
              noProblem = true;
            }
          },
          function() {
            showErrorDialog(
              'エラーが発生しました。',
              'もう一度実行するか、解決しない場合は管理者に知らせてください。'
            );
          }
        );
      }
      return noProblem;
    },
    resetState() {
      this.targets.forEach(target => {
        target.initialName = null;
      });
      this.allTargetNames = [];
      this.compoundList = [];
    },
    resetComposer(kekuleId) {
      const composer = Kekule.Widget.getWidgetOnElem(
        document.getElementById(kekuleId)
      );
      if (composer) {
        composer.newDoc();
      }
    },
    async nextStep() {
      this.inTransition = true;
      let isError = false;
      if (this.step === 1) {
        const emptyProfile = this.prepareTargetsRegistration().every(
          p => isEmpty(p.initial_name) &&
                         isEmpty(p.prediction_model)
        );
        if (emptyProfile) {
          Swal.fire({
            icon: 'warning',
            title: '警告',
            text: 'プロファイルを設定してください。'
          });
          isError = true;
        } else {
          if (document.getElementById('kekule-profile-settings')) {
            // Create sdf from compound editor
            await this.setCompoundFromEditor(
              this.readCompoundFromEditor('kekule-profile-settings')
            );
            if (!this.isCorrectDrawnCompound) {
              isError = true;
            }
          }
          this.updateDesign |=
                    this.cachedTargets !== JSON.stringify(this.targets);
          if (this.updateDesign) {
            const canContinue = await this.registerProfile();
            if (!canContinue) {
              isError = true;
            }
          }
        }
      }
      if (this.step === 2) {
        this.evaluateRewardFromTarget();
      }
      this.inTransition = false;
      if (!isError) {
        this.step += 1;
      }
    },
    // Molecule Editor
    drawCompound() {
      const composer = Kekule.Widget.getWidgetOnElem(
        document.getElementById('kekule-generation-settings')
      );
      if (composer && this.baseCompound) {
        const mol = Kekule.IO.loadFormatData(
          this.baseCompound.mol,
          'mol'
        );
        composer.setChemObj(mol);
        // smiles from backend and kekule are not same
        this.baseCompound.smiles = Kekule.IO.saveFormatData(
          composer.getChemObj(),
          'smi'
        );
      }
    },
    initComposer() {
      const kekule = document.getElementById('kekule-generation-settings');
      if (!kekule.hasChildNodes()) {
        const composer = new Kekule.Editor.Composer(kekule);
        composer.setDimension('600px', '400px');
        composer.setPredefinedSetting('molOnly');
        composer.getRenderConfigs()
          .getColorConfigs()
          .setUseAtomSpecifiedColor(true);
        composer.getEditorConfigs()
          .getInteractionConfigs()
          .setEditorInitialZoom(0.8);
      }
    },
    registerPreset() {
      this.isLoading = true;
      const validProfiles = this.prepareTargetsRegistration().filter(
        p => !isEmpty(p.initial_name) ||
             !isEmpty(p.prediction_model)
      );
      if (validProfiles.length > 0) {
        Swal.fire({
          title: 'プリセット登録',
          text: '入力されたプロファイルがプリセットとして登録されます。\nプリセット名を入力してください。',
          input: 'text',
          inputAttributes: {
            autocapitalize: 'off'
          },
          inputValidator: (value) => {
            if (!value) {
              return 'プリセット名を入力してください';
            } else if (value.length > this.maxPresetNameLength) {
              return `プリセット名は${this.maxPresetNameLength}文字以下にしてください`;
            } else if (this.presetNames.includes(value.trim())) {
              return 'プリセット名は既に使用されています';
            }
          },
          showCancelButton: true,
          inputPlaceholder: '〇〇用プリセット',
          confirmButtonText: '登録',
          cancelButtonText: 'キャンセル',
          showLoaderOnConfirm: true,
          preConfirm: (presetName) => {
            const self = this;
            this.api.registerPreset(
              {
                name: `${presetName}`,
                profiles: validProfiles
              },
              function(data) {
                showSuccessDialog(
                  '完了',
                  'プリセットの登録が完了しました。'
                );
                self.loadPresets();
              },
              function() {
                showErrorDialog(
                  '登録に失敗しました。',
                  'プロファイルの値を確認してください。'
                );
              }
            );
          },
          allowOutsideClick: () => !Swal.isLoading()
        });
      } else {
        Swal.fire({
          icon: 'warning',
          title: '警告',
          text: '入力されたプロファイルがプリセットとして登録されます。'
        });
      }
    },
    changeMemo(memo) {
      this.memo = memo;
    },
    changePriority(itemId, priority) {
      const target = this.targets.find(t => t.id === itemId);
      target.priority = priority;
      if (this.rewardSettingValue) {
        const reward = this.rewardSettingValue.settings.find(r => r.origin === target.objectiveServer);
        if (reward) {
          reward.weight = priority;
        }
      }
    },
    onSelectSGModule(module) {
      this.selectedSGModule = module;
    },
    async setCompoundFromEditor(compound) {
      const blob = new Blob([compound.mol], { type: 'chemical/x-mdl-sdfile' });
      this.files.input_sdf = blob;
      this.validateMoleculeSelection('kekule-profile-settings');
      await this.loadCompounds(true);
      this.updateDesign = true;
    },
    evaluateRewardFromTarget() {
      let allOsFiles = {};
      const rewardSettings = this.targets
        .filter(item => item.objectiveServer)
        .map((item, index) => {
          const scalingType = item.scalingType === 'step'
            ? 'threshold'
            : item.scalingType;
          let goodValue = '';
          let threshold = {};
          if (['none', 'threshold', 'sigmoid'].includes(scalingType)) {
            goodValue = item.invert ? 'low' : 'high';
          } else {
            goodValue = item.invert ? 'out' : 'in';
          }
          if (scalingType !== 'none') {
            threshold = {
              'value1': item.value1,
              'value2': item.value2
            };
          }
          if (scalingType === 'range') {
            threshold.value3 = item.value3;
            threshold.value4 = item.value4;
          }
          const osParamsFiles = { ...item.osParameters, ...item.osFiles };
          const rewardSetting = {
            origin: item.objectiveServer,
            objective_value: `${item.predictionDisplayName}_reward`,
            display_name: `${item.predictionDisplayName}`,
            weight: item.priority,
            scaling: scalingType,
            good_value: goodValue,
            ...osParamsFiles
          };
          if (scalingType !== 'none') {
            rewardSetting.threshold = threshold;
          }
          if (item.osFiles) {
            allOsFiles = {
              ...allOsFiles,
              ...Object.keys(item.osFiles).reduce((acc, key) => {
                acc[`reward_config___settings___${index}___${key}`] = item.osFiles[key];
                return acc;
              }, {})
            };
          }
          return rewardSetting;
        });
      this.rewardSettingValue = { settings: rewardSettings };
      if (!this.preGenSetting) this.preGenSetting = {};
      this.preGenSetting.sgModelFiles = { ...this.preGenSetting.sgModelFiles, ...allOsFiles };
    },
    loadParentDesignSettings(parentDesignId) {
      this.isLoading = true;
      const self = this;
      this.api.getDesignSettings(
        parentDesignId,
        function(data) {
          for (const reward of data.reward_config.settings) {
            const target = self.targets.find(t => t.objectiveServer === reward.origin);
            if (target) {
              target.priority = reward.weight;
            }
          }
          self.preGenSetting = data;
        },
        function(error) {
          console.log(error);
        }
      );
    },
    removeOSInputFromRewardSetting() {
      const rewardCompulsoryKeys = [
        'origin', 'objective_value', 'display_name', 'weight', 'scaling', 'good_value', 'threshold'];
      if (!this.rewardSettingValue) {
        return;
      }
      this.rewardSettingValue.settings.forEach(reward => {
        for (const key in reward) {
          if (!rewardCompulsoryKeys.includes(key)) {
            reward[key] = null;
          }
        }
      });
    }
  }
};
</script>

<style scoped>
.stepper-header {
  background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(0,176,255,0.6) 100%);
  font-weight: bold;
}
:deep(.v-stepper),
:deep(.v-stepper__items),
:deep(.v-stepper__wrapper) {
  overflow: unset;
}
.preset-footer-divider {
  position: sticky;
  bottom: 52px; /* Would be better to use a variable */
}
.preset-card-action {
  position: sticky;
  bottom: 0px;
  background-color: white;
}
.sticky-top {
  position: sticky;
  top: 0px; /* Would be better to use a variable */
  background-color: white;
  z-index: 2;
}
.button-pt-5px {
  padding-top: 5px;
}
</style>
