<template>
  <v-data-table
    class="elevation-1"
    item-key="id"
    :headers="headers"
    :items="ais"
    :item-class="() => 'clickable'"
    :items-per-page="itemsPerPage"
    :loading="isLoading"
    loading-text="ロード中..."
    no-data-text="データがありません。"
    fixed-header
    :height="tableHeight"
    hide-default-footer
    :search="search"
    @click:row="call"
    @pagination="onPaginationChange"
  >
    <template #[`item.description`]="{ item }">
      <div class="my-1 description-short">{{ (item.description) }}</div>
    </template>
    <template #[`item.action`]="{ item }">
      <v-tooltip v-if="item.has_info_file" :key="item.id" top>
        <template #activator="{ on }">
          <v-btn
            icon
            class="mr-2"
            color="primary"
            :loading="isDownloading[item.id]"
            v-on="on"
            @click.stop="downloadInfoFile(item.id)"
          >
            <v-icon>mdi-download</v-icon>
          </v-btn>
        </template>
        <span>Download Information File.</span>
      </v-tooltip>
    </template>
    <template #top="{ pagination, options, updateOptions }">
      <v-data-footer
        :pagination="pagination"
        :options="options"
        :items-per-page-options="itemsPerPageOptions"
        items-per-page-text="$vuetify.dataTable.itemsPerPageText"
        :show-first-last-page="true"
        @update:options="updateOptions"
      >
        <template #prepend>
          <SearchField
            @input="search = $event"
          />
        </template>
      </v-data-footer>
    </template>
  </v-data-table>
</template>

<script>
import router from '../router';
import SearchField from '@/components/SearchField';
import { tableHeightWithMargin, showErrorDialog } from '@/mixins/utils';
import { ITEMS_PER_PAGE_OPTIONS } from '@/env';

export default {
  name: 'MLTable',
  components: {
    SearchField
  },
  data() {
    return {
      search: '',
      headers: [
        { text: 'ID', value: 'id', width: '65px' },
        { text: 'Name', value: 'name', width: '200px' },
        { text: 'Type', value: 'type_display', width: '150px' },
        { text: 'Category', value: 'category' },
        { text: 'Description', value: 'description', width: '300px', sortable: false },
        { text: 'Creator', value: 'creator' },
        { text: 'Number of Task', value: 'number_of_tasks' },
        { text: 'Prediction Target', value: 'prediction_target', width: '150px' },
        { text: 'Unit', value: 'unit', width: '150px', sortable: false },
        { text: 'Explanatory variable', value: 'explanatory_variable', width: '150px', sortable: false },
        { text: 'Prediction algorithm', value: 'prediction_algorithm', width: '150px', sortable: false },
        { text: 'Size of training data', value: 'size_of_training_data' },
        { text: 'Benchmark', value: 'benchmark', width: '150px', sortable: false },
        { text: 'Predictive performance (R²)', value: 'r_squared' },
        { text: 'ROC score', value: 'roc_score' },
        { text: 'Action', value: 'action' }
      ],
      ais: [],
      isLoading: true,
      itemsPerPage:
        Number(localStorage.getItem('ml-ipp')) ||
        ITEMS_PER_PAGE_OPTIONS[0],
      itemsPerPageOptions: ITEMS_PER_PAGE_OPTIONS,
      isDownloading: {}
    };
  },
  computed: {
    tableHeight: function() {
      return tableHeightWithMargin(0);
    }
  },
  mounted() {
    const self = this;

    this.api.getMLModules(function(list) {
      self.ais = list;
      self.isLoading = false;
    }, function(error) {
      console.log(error);
      self.isLoading = false;
    });
  },
  methods: {
    call(params) {
      const id = params.id;
      router.push({ name: 'ExecuteModel', params: { id: id } });
    },
    onPaginationChange(pagination) {
      if (pagination.itemsPerPage) {
        this.itemsPerPage = pagination.itemsPerPage;
        localStorage.setItem('ml-ipp', pagination.itemsPerPage);
      }
    },
    downloadInfoFile(moduleId) {
      this.isDownloading[moduleId] = true;
      const self = this;
      this.api.downloadModuleInfoFile(
        moduleId,
        function(response) {
          const filename = decodeURI(response.headers['content-disposition']
            .split('filename=')[1]
          );
          self.api.downloadFileHelper(response, `${filename}`);
          self.isDownloading = {};
        },
        function() {
          self.isDownloading = {};
          showErrorDialog(
            'File not found',
            'No file found with information.'
          );
        }
      );
    }
  }
};
</script>

<style scoped>
:deep(.v-data-table-header th) {
  white-space: nowrap;
}
:deep(table > tbody > tr > td:nth-child(1)),
:deep(table > thead > tr > th:nth-child(1)) {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 0;
  z-index: 7;
  background: white;
}
:deep(table > thead > tr > th:nth-child(1)) {
  z-index: 8;
}
:deep(table > tbody > tr > td:nth-child(2)),
:deep(table > thead > tr > th:nth-child(2)) {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 65px; /* Width of first column */
  z-index: 7;
  background: white;
}
:deep(table > thead > tr > th:nth-child(2)) {
  z-index: 8;
}
</style>
