<template>
  <v-container>
    <v-row justify="center" align-content="center" style="min-height: 500px">
      <v-card class="elevation-0">
        <v-row no-gutters>
          <v-col>
            <v-list-item three-line>
              <v-list-item-content>
                <div class="text-h3 mb-4">
                  メンテナンス中。
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'MaintenancePage'
};
</script>
