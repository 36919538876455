<template>
  <v-tooltip top>
    <template #activator="{ on, attrs }">
      <v-icon v-bind="attrs" v-on="on">
        {{
          visibility === 'OnlyMe' ? 'mdi-account-circle'
          : visibility === 'Everyone' ? 'mdi-account-group'
            : visibility === 'ToGroup' ? 'mdi-family-tree' : ''
        }}
      </v-icon>
    </template>
    <span>{{
      getTooltipText(visibility)
    }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: 'VisibilityIcon',
  props: {
    visibility: {
      type: String,
      required: true
    }
  },
  methods: {
    getTooltipText(visibility) {
      return visibility === 'OnlyMe'
        ? 'Only Me'
        : visibility === 'Everyone'
          ? 'Everyone'
          : visibility === 'ToGroup' ? 'Group' : '';
    }
  }
};
</script>
