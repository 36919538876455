<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="executions"
      :loading="isLoading"
      loading-text="ロード中..."
      no-data-text="データがありません。"
      sort-desc
      multi-sort
      class="elevation-1"
      hide-default-footer
      disable-pagination
    >
      <template #[`item.memo`]="{ value }">
        <div class="text-truncate" style="max-width: 200px">
          {{ value }}
        </div>
      </template>
      <template #[`item.visibility`]="{ value }">
        <VisibilityIcon :visibility="value" />
      </template>
      <template #[`item.action`]="{ item }">
        <v-row>
          <v-btn
            class="ma-2"
            color="primary"
            @click="openWorkflowResult(item.id)"
          >
            See Result
          </v-btn>
          <CancelExecutionButton
            v-if="isCancelableExecution(item)"
            :execution-id="item.id"
            execution-type="workflow"
            @refresh="refresh"
          />
          <v-btn
            v-if="isDeletableExecution(item)"
            class="ma-2"
            color="error"
            @click.stop="confirmDeletion(item)"
          >
            Delete
          </v-btn>
        </v-row>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import router from '../router';
import CancelExecutionButton from '@/components/CancelExecutionButton.vue';
import VisibilityIcon from '@/components/VisibilityIcon.vue';

import {
  showConfirmDialog,
  showSuccessDialog,
  showErrorDialog
} from '@/mixins/utils';

export default {
  name: 'WorkflowExecutionTable',
  components: {
    CancelExecutionButton,
    VisibilityIcon
  },
  props: {
    executions: {
      type: Array,
      default: () => []
    },
    isLoading: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      headers: [
        { text: 'Id', value: 'id', width: '5%' },
        { text: 'Status', value: 'status', width: '5%' },
        { text: 'Memo', value: 'memo', width: '35%' },
        { text: 'Begin', value: 'begin_at', width: '5%' },
        { text: 'End', value: 'end_at', width: '5%' },
        { text: 'Visibility', value: 'visibility', width: '5%' },
        { text: 'Action', value: 'action', sortable: false, width: '40%' }
      ]
    };
  },
  methods: {
    refresh() {
      this.$emit('refresh');
    },
    openWorkflowResult: function(id) {
      router.push({ name: 'WorkflowResult', params: { id: id } });
    },
    isCancelableExecution(execution) {
      const isExecutor = execution.executor === this.$session.get('user').id;
      const isCancelableStatus = (execution.end_at === null) && (execution.status !== 'Canceled');
      return isExecutor && isCancelableStatus;
    },
    confirmDeletion(execution) {
      const self = this;
      showConfirmDialog({
        title: `Workflow Execution ${execution.id} will be deleted.`,
        html: 'This operation cannot be undone. Are you sure you want to delete it?',
        approveCB: () => {
          self.api.deleteWorkflowExecution(
            execution.id,
            function(data) {
              showSuccessDialog('', `Workflow Execution ${execution.id} was deleted.`);
              self.refresh();
            },
            function(e) {
              showErrorDialog(
                'An unexpected error occurred',
                `Try again or let an administrator
                          know if the problem persists.`
              );
            }
          );
        }
      });
    },
    isDeletableExecution(execution) {
      const isDeletableStatus = (execution.end_at !== null) || (execution.status === 'Canceled');
      return execution.visibility === 'OnlyMe' && isDeletableStatus;
    }
  }
};
</script>
