<template>
  <v-stepper v-if="process.length > 0" v-model="step" non-linear vertical>
    <template v-for="(p, indx) in process">
      <v-stepper-step
        :key="`step_${indx+1}`"
        :step="0"
        :complete="p.module!==null"
        :editable="false"
      >
        Step {{ indx+1 }}
        <small v-if="p.module">{{ p.module.name }}</small>
      </v-stepper-step>
      <v-stepper-content
        :key="`step_content_${indx+1}`"
        :step="0"
      >
        <v-btn
          class="dialog-button"
          color="primary"
          @click="openDialog(indx)"
        >
          SEE PARAMETERS
        </v-btn>
      </v-stepper-content>
    </template>
    <v-dialog v-model="dialog" width="600">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Input parameters
        </v-card-title>

        <v-card-text>
          <ExecutionInputValue
            v-for="(input, index) in inputsToDisplay"
            :key="index"
            :name="input.name"
            :info="input"
            :reward-config="name==='reward_config'"
            @onFileDownload="downloadInputFile"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-stepper>
</template>

<script>
import { showErrorDialog } from '@/mixins/utils';
import ExecutionInputValue from './ExecutionInputValue.vue';

export default {
  name: 'WorkflowExecutionInputs',
  components: {
    ExecutionInputValue: ExecutionInputValue
  },
  props: {
    executionId: {
      // list of object { module: {}, inputs: [{}, {}] }
      type: [String, Number],
      default: null,
      required: true
    }
  },
  data() {
    return {
      step: 0,
      process: [],
      dialog: false,
      selectedStep: null,
      inputsToDisplay: []
    };
  },
  mounted() {
    const self = this;
    if (this.executionId != null) {
      this.api.getWorkflowExecutionInputs(
        self.executionId,
        function(data) {
          self.process = data.inputs;
        }
      );
    }
  },
  methods: {
    downloadInputFile(fileName) {
      this.api.downloadWorkFlowExecutionInputFile(
        this.executionId,
        fileName,
        this.selectedStep,
        function(error) {
          showErrorDialog('Error', error.data);
        });
    },
    openDialog(index) {
      if (this.process.length > index) {
        this.selectedStep = index + 1;
        this.inputsToDisplay = this.process[index].inputs;
      }
      this.$nextTick(() => {
        this.dialog = true;
      });
    }
  }
};
</script>

<style scoped>
.dialog-button {
  margin-bottom: 5px;
}
</style>
