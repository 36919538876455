<template>
  <v-card class="pa-5">
    <v-card-title class="justify-center">{{ model.name }}</v-card-title>
    <v-card-text>
      {{ message }}
    </v-card-text>

    <v-card-actions>
      <v-btn color="alert" @click="cancel">Cancel</v-btn>
      <v-spacer />
      <v-btn
        color="error"
        @click="submit"
      >
        Unlink
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'ModelUnlinkDialog',
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  computed: {
    message: function() {
      let m = 'Unlink from ';
      m += this.model.type === 'Objective Server'
        ? 'prediction model?'
        : 'objective server?';
      return m;
    }
  },
  methods: {
    cancel() {
      this.$emit('cancel');
    },
    submit() {
      this.$emit('submit', this.model);
    }
  }
};
</script>
