<template>
  <v-stepper v-if="process.length > 0" v-model="step" non-linear vertical>
    <template v-for="(p, indx) in process">
      <v-stepper-step
        :key="`step_${indx+1}`"
        :step="indx+1"
        :complete="p.module!==null"
        :editable="createView"
      >
        Step {{ indx+1 }}
        <small v-if="p.module">{{ p.module.name }}</small>
        <small v-else>Select Module</small>
      </v-stepper-step>
      <v-stepper-content
        :key="`step_content_${indx+1}`"
        :step="indx+1"
      >
        <v-row v-if="createView">
          <v-col>
            <v-autocomplete
              v-model="p.module"
              :items="modules"
              item-value="id"
              item-text="name"
              label="Select Module"
              return-object
            />
          </v-col>
          <v-col align-self="center">
            <v-btn
              v-show="p.module && indx === process.length - 1"
              color="primary"
              @click="addStep"
            >
              <v-icon>mdi-plus</v-icon>
              Add Step
            </v-btn>
          </v-col>
          <v-spacer />
          <v-col
            v-if="process.length > 1"
            align="right"
            align-self="start"
          >
            <v-tooltip top>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  color="red"
                  v-bind="attrs"
                  v-on="on"
                  @click="removeStep(indx)"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </template>
              <span>Remove step</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-stepper-content>
    </template>
  </v-stepper>
</template>

<script>

export default {
  name: 'StepList',
  props: {
    process: {
      type: Array,
      default: () => [],
      required: true
    }
  },
  data() {
    return {
      step: 0,
      modules: [],
      createView: !this.$route.params.id
    };
  },
  mounted() {
    if (this.createView) {
      const self = this;
      this.api.getMLModules(
        function(modules) {
          self.modules = modules.map(m => {
            return { id: m.id, name: m.name };
          });
        },
        function(error) {
          console.log(error);
        },
        {
          ordering: 'name'
        }
      );
      this.addStep();
    }
  },
  methods: {
    addStep() {
      this.$emit('addStep');
      this.$nextTick(() => {
        this.step += 1;
      });
    },
    removeStep(index) {
      this.$emit('removeStep', index);
      if (this.step > this.process.length) {
        this.$nextTick(() => {
          this.step -= 1;
        });
      }
    }
  }
};
</script>
