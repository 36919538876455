<template>
  <v-card class="pa-5">
    <v-card-title>
      Link "{{ originModel.name }}" to
    </v-card-title>

    <v-autocomplete
      v-model="selectedModelId"
      :items="linkableModels"
      item-value="id"
      item-text="name"
      required
    />
    <v-card-actions>
      <v-btn color="alert" @click="cancel">Cancel</v-btn>
      <v-spacer />
      <v-btn
        color="warning"
        :disabled="disableSubmit"
        @click="submit"
      >
        Link
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'ModelLinkDialog',
  components: {
  },
  props: {
    linkableModels: {
      type: Array,
      required: true
    },
    originModel: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      selectedModelId: null
    };
  },
  computed: {
    disableSubmit: function() {
      return this.selectedModelId === null;
    }
  },
  methods: {
    cancel() {
      this.$emit('cancel');
    },
    submit() {
      if (this.originModel.type === 'Objective Server') {
        this.$emit('submit', this.selectedModelId, this.originModel.id);
      } else {
        this.$emit('submit', this.originModel.id, this.selectedModelId);
      }
    }
  }
};
</script>
