<template>
  <v-btn
    @click="openSettingAssistant"
  >
    Settings Assistant
    <v-icon class="ml-1">mdi-open-in-new</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'SettingsAssistantButton',
  methods: {
    openSettingAssistant() {
      window.open('https://duckduckgo.com/', '_blank');
    }
  }
};
</script>
