<template>
  <Error :error-code="404" />
</template>

<script>
import Error from '@/components/Error';
export default {
  name: 'NotFoundPage',
  components: {
    Error: Error
  }
};
</script>
