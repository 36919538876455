<template>
  <v-container grid-list-md>
    <v-layout row wrap align-center justify-center fill-height>
      <v-flex xs12 sm8 lg4 md5>
        <v-card class="login-card">
          <v-card-title>
            <span class="headline">Login to ML Platform</span>
          </v-card-title>

          <v-spacer />

          <v-card-text>
            <v-layout
              v-if="loading"
              row
              fill-height
              justify-center
              align-center
            >
              <v-progress-circular
                :size="50"
                color="primary"
                indeterminate
              />
            </v-layout>
            <v-form
              v-else
              ref="form"
              v-model="valid"
              @keyup.native.enter="login"
            >
              <v-container>
                <v-text-field
                  v-model="credentials.username"
                  label="ユーザ名"
                  :rules="[v => !!v || 'ユーザ名は必須です']"
                  required
                />
                <v-text-field
                  v-model="credentials.password"
                  type="password"
                  label="パスワード"
                  :rules="[v => !!v || 'パスワードは必須です']"
                  required
                />
              </v-container>
              <v-btn
                class="pink white--text"
                :disabled="!valid"
                @click="login"
              >
                Login
              </v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import Swal from 'sweetalert2';
import router from '../router';

export default {
  name: 'Auth',
  data: () => ({
    credentials: {},
    valid: false,
    loading: false
  }),
  methods: {
    login() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        const self = this;
        this.api.authenticateUser(this.credentials, function(res) {
          self.$session.start();
          self.$session.set('token', res.data.token);
          self.$session.set('tokenInitTime', Date.now());
          router.push('/');
        }, function() {
          self.loading = false;
          Swal.fire({
            icon: 'error',
            text: 'ユーザー名もしくはパスワード、または両方が間違っています',
            showConfirmButton: false,
            showCloseButton: false,
            timer: 3000
          });
        });
      }
    }
  }
};
</script>
