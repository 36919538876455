<template>
  <v-data-table
    class="elevation-1"
    :headers="headers"
    :items="executions"
    :item-class="() => 'clickable'"
    :loading="isLoading"
    loading-text="ロード中..."
    no-data-text="データがありません。"
    item-key="id"
    sort-by="created_at"
    sort-desc
    :items-per-page="itemsPerPage"
    fixed-header
    :height="tableHeight"
    hide-default-footer
    :search="search"
    @click:row="show"
    @pagination="onPaginationChange"
  >
    <template #[`item.action`]="{ item }">
      <v-tooltip
        v-if="isDeletable(item)"
        :key="item.id"
        top
      >
        <template #activator="{ on }">
          <v-btn
            icon
            class="mr-2"
            color="error"
            v-on="on"
            @click.stop="confirmDeletion(item)"
          >
            <v-icon>mdi-delete-forever</v-icon>
          </v-btn>
        </template>
        <span>削除</span>
      </v-tooltip>
    </template>
    <template #top="{ pagination, options, updateOptions }">
      <v-data-footer
        :pagination="pagination"
        :options="options"
        :items-per-page-options="itemsPerPageOptions"
        items-per-page-text="$vuetify.dataTable.itemsPerPageText"
        :show-first-last-page="true"
        @update:options="updateOptions"
      >
        <template #prepend>
          <SearchField
            @input="search = $event"
          />
        </template>
      </v-data-footer>
    </template>
  </v-data-table>
</template>

<script>
import router from '../router';
import SearchField from '@/components/SearchField';
import {
  showConfirmDialog,
  showSuccessDialog,
  showErrorDialog,
  tableHeightWithMargin
} from '@/mixins/utils';
import { ITEMS_PER_PAGE_OPTIONS } from '@/env';

export default {
  name: 'ExecutionTable',
  components: {
    SearchField
  },
  data() {
    return {
      search: '',
      headers: [
        { text: 'ID', value: 'id', filterable: false },
        { text: 'AI Name', value: 'name' },
        { text: 'Status', value: 'status', filterable: false },
        { text: 'Begin Datetime', value: 'begin_at', filterable: false },
        { text: 'End Datetime', value: 'end_at', filterable: false },
        { text: 'Executor', value: 'executor', filterable: true },
        { text: 'Action', value: 'action', filterable: false }
      ],
      executions: [],
      isLoading: true,
      itemsPerPage:
        Number(localStorage.getItem('exec-ipp')) ||
        ITEMS_PER_PAGE_OPTIONS[0],
      itemsPerPageOptions: ITEMS_PER_PAGE_OPTIONS
    };
  },
  computed: {
    tableHeight: function() {
      return tableHeightWithMargin(0);
    }
  },
  mounted() {
    this.checkLoggedIn(this.$session);
    this.loadExecutions();
  },
  methods: {
    loadExecutions() {
      const self = this;
      this.api.loadExecutions(function(list) {
        for (const index in list) {
          list[index].name = list[index].module.name;
          list[index].id = String(list[index].id);
        }
        self.executions = list;
        self.isLoading = false;
      }, function(error) {
        console.log(error);
        self.isLoading = false;
      });
    },
    onPaginationChange(pagination) {
      if (pagination.itemsPerPage) {
        this.itemsPerPage = pagination.itemsPerPage;
        localStorage.setItem('exec-ipp', pagination.itemsPerPage);
      }
    },
    show(params) {
      const id = params.id;
      router.push({ name: 'ExecutionView', params: { id: id } });
    },
    confirmDeletion(execution) {
      const self = this;
      showConfirmDialog({
        title: `Execution ${execution.id} will be deleted.`,
        html: 'This operation cannot be undone. Are you sure you want to delete it?',
        approveCB: () => {
          self.api.deleteExecution(
            execution.id,
            function(data) {
              showSuccessDialog('', `Execution ${execution.id} was deleted.`);
              self.loadExecutions();
            },
            function(e) {
              showErrorDialog(
                'An unexpected error occurred',
                `Try again or let an administrator
                          know if the problem persists.`
              );
            }
          );
        }
      });
    },
    isDeletable(execution) {
      return execution.visibility === 'OnlyMe' &&
        (execution.status === 'CANCELED' || execution.end_at !== null);
    }
  }
};
</script>
