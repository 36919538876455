/* eslint-disable vue/max-len */
import Vue from 'vue';
import camelcaseKeys from 'camelcase-keys';
import { paramsSerializer } from '@/mixins/utils';

const AUTH_URI = '/auth';
const API_URI = '/api';

const getProcessExecutionStatus = function(callback, errorCallback) {
  Vue.axios.get(
    `${API_URI}/execution_status/`
  ).then(function(response) {
    callback(response.data);
  }).catch(function(e) {
    errorCallback(e);
  });
};

const authenticateUser = function(credentials, successCallback, errorCallback) {
  Vue.axios.post(`${AUTH_URI}/`, credentials)
    .then(successCallback)
    .catch(errorCallback);
};

const refreshToken = function(token, successCallback, errorCallback) {
  Vue.axios.post(`${AUTH_URI}/refresh/`, { token })
    .then(successCallback)
    .catch(errorCallback);
};

const getUserInfo = function(token, successCallback, errorCallback) {
  const config = { 'headers': { Authorization: `JWT ${token}` } };
  Vue.axios.get(`${AUTH_URI}/me/`, config)
    .then(successCallback)
    .catch(function(e) {
      if (typeof errorCallback === 'function') {
        errorCallback(e);
      }
    });
};

const getNonMembers = function(groupId, callback, errorCallback) {
  Vue.axios.get(
    `${AUTH_URI}/group/${groupId}/nonmembers/`
  ).then(function(response) {
    callback(response);
  }).catch(function(e) {
    errorCallback(e);
  });
};

const getGroupMembers = function(groupId, callback, errorCallback) {
  Vue.axios.get(
    `${AUTH_URI}/group/${groupId}/members/`
  ).then(function(response) {
    callback(response);
  }).catch(function(e) {
    errorCallback(e);
  });
};

const addGroupMember = function(groupId, userId, callback, errorCallback) {
  Vue.axios.post(
    `${AUTH_URI}/group/${groupId}/addmember/`,
    { userId }
  ).then(function(response) {
    callback(response);
  }).catch(function(e) {
    errorCallback(e);
  });
};

const removeGroupMember = function(groupId, userId, callback, errorCallback) {
  Vue.axios.post(
    `${AUTH_URI}/group/${groupId}/removemember/`,
    { userId }
  ).then(function(response) {
    callback(response);
  }).catch(function(e) {
    errorCallback(e);
  });
};

const getMLModules = async function(callback, errorCallback, params = null) {
  await Vue.axios.get(`${API_URI}/ai/`, {
    params: params,
    paramsSerializer: paramsSerializer
  }).then(function(response) {
    callback(response.data);
  }).catch(function(e) {
    errorCallback(e);
  });
};

const getModelLinkableModules = async function(callback, errorCallback, id) {
  const params = {
    type: 'all'
  };
  await Vue.axios.get(`${API_URI}/ai/${id}/linkable_modules/`,
    {
      params: params,
      paramsSerializer: paramsSerializer
    }
  ).then(function(response) {
    callback(response.data);
  }).catch(function(e) {
    errorCallback(e);
  });
};

const patchMLModules = function(id, data, callback, errorCallback) {
  Vue.axios.patch(`${API_URI}/ai/${id}/`, data).then(function(response) {
    callback(response.data);
  }).catch(function(e) {
    errorCallback(e);
  });
};

const loadWorkflows = function(callback, errorCallback) {
  Vue.axios.get(`${API_URI}/workflow/`).then(function(response) {
    callback(response.data);
  }).catch(function(e) {
    errorCallback(e);
  });
};

const getMLModule = function(moduleId, callback, errorCallback, isOption = false, isReadOnly = false) {
  const url = `${API_URI}/ai/${moduleId}/`;
  Vue.axios.get(url, {
    params: {
      ...(isOption ? { option: true } : {}),
      ...(isReadOnly ? { readonly: true } : {})
    }
  }).then(function(response) {
    callback(response.data);
  }).catch(function(e) {
    if (typeof errorCallback === 'function') {
      errorCallback(e);
    }
  });
};

const getMLModuleWithVersionInfo = function(moduleId, callback, errorCallback) {
  const url = `${API_URI}/ai/${moduleId}/?versioning=true`;
  Vue.axios.get(url).then(function(response) {
    callback(response.data);
  }).catch(function(e) {
    if (typeof errorCallback === 'function') {
      errorCallback(e);
    }
  });
};

const getMLModuleByName = function(name, callback, errorCallback, isReadOnly = false) {
  const url = `${API_URI}/ai/?name=${name}`;
  Vue.axios.get(url, {
    params: {
      ...(isReadOnly ? { readonly: true } : {})
    }
  }).then(function(response) {
    const module = response.data.length > 0 ? response.data[0] : null;
    callback(module);
  }).catch(function(e) {
    errorCallback(e);
  });
};

const deleteMLModule = function(moduleId, callback, errorCallback) {
  const url = `${API_URI}/ai/${moduleId}/`;
  Vue.axios.delete(url).then(function(response) {
    callback(response.data);
  }).catch(function(e) {
    if (typeof errorCallback === 'function') {
      errorCallback(e);
    }
  });
};

// eslint-disable-next-line vue/max-len
const executeMLModule = function(params, parameters, files, callback, errorCallback) {
  const formData = new FormData();
  for (const name in files) {
    if (Array.isArray(files[name])) {
      for (const file of files[name]) {
        formData.append(name, file);
      }
    } else {
      formData.append(name, files[name]);
    }
  }
  formData.append('parameters', JSON.stringify(parameters));
  const config = {
    params: params,
    paramsSerializer: paramsSerializer
  };
  if (files.length > 0) {
    config.headers = {
      'content-type': 'multipart/form-data'
    };
  }

  Vue.axios.post(
    `${API_URI}/execute/`, formData, config
  ).then(function(response) {
    callback(response.data);
  }).catch(function(e) {
    errorCallback(e);
  });
};

const loadExecutions = function(callback, errorCallback) {
  Vue.axios.get(`${API_URI}/executions/`).then(function(response) {
    callback(response.data);
  }).catch(function(e) {
    errorCallback(e);
  });
};

const getExecution = function(id, callback, errorCallback) {
  Vue.axios.get(`${API_URI}/executions/${id}/`).then(function(response) {
    callback(response.data);
  }).catch(function(e) {
    errorCallback(e);
  });
};

const deleteExecution = function(id, callback, errorCallback) {
  Vue.axios.delete(`${API_URI}/executions/${id}/`).then(function(response) {
    callback(response.data);
  }).catch(function(e) {
    errorCallback(e);
  });
};

const getExecutionOutput = async function(id, dataTableOptions, controllerSignal, callback, errorCallback) {
  await Vue.axios.get(
    `${API_URI}/executions/${id}/output/`, {
      params: {
        page: dataTableOptions.page,
        page_size: dataTableOptions.itemsPerPage,
        sort_by: dataTableOptions.sortBy,
        sort_desc: dataTableOptions.sortDesc
      },
      paramsSerializer: paramsSerializer,
      signal: controllerSignal
    }).then(function(response) {
    if (response) {
      callback(response.data);
    }
  }).catch(function(e) {
    typeof errorCallback === 'function' && errorCallback(e);
  });
};

const downloadExecutionFile = function(id, fileName, errorCallback) {
  Vue.axios.get(`${API_URI}/executions/${id}/file/?filename=${fileName}`
  ).then(function(response) {
    const ext = fileName.split('.').pop();
    downloadFileHelper(response, fileName, ext === 'json');
  }).catch(function(e) {
    errorCallback(e);
  });
};

const getExecutionInputs = function(id, callback, errorCallback) {
  Vue.axiosNoResponseInterceptor.get(
    `${API_URI}/executions/${id}/inputs/`
  ).then(function(response) {
    callback(response.data);
  }).catch(function(e) {
    errorCallback(e);
  });
};

const downloadExecutionInputFile = function(id, fileName, errorCallback) {
  Vue.axios.get(
    `${API_URI}/executions/${id}/file/?filename=${fileName}&inputFile=true`
  ).then(function(response) {
    const ext = fileName.split('.').pop();
    downloadFileHelper(response, fileName, ext === 'json');
  }).catch(function(e) {
    errorCallback(e);
  });
};

const downloadExecutionWorkingFolder = async function(id, source, callback, errorCallback) {
  await Vue.axios.get(
    `${API_URI}/${source}/${id}/download_work_dir/`,
    { responseType: 'blob' }
  ).then(function(response) {
    const fileName = response.headers['content-disposition'].split('filename=')[1];
    downloadFileHelper(response, fileName);
    callback();
  }).catch(function(e) {
    errorCallback(e);
  });
};

const registerProject = function(parameters, callback, errorCallback) {
  Vue.axios.post(
    `${API_URI}/project/`,
    parameters
  ).then(function(response) {
    callback(response.data);
  }).catch(function(e) {
    errorCallback(e);
  });
};

const loadProjects = function(callback, errorCallback) {
  Vue.axios.get(
    `${API_URI}/project/`
  ).then(function(response) {
    callback(response.data);
  }).catch(function(e) {
    errorCallback(e);
  });
};

const loadProject = function(projectId, callback, errorCallback) {
  Vue.axios.get(
    `${API_URI}/project/${projectId}/`
  ).then(function(response) {
    typeof callback === 'function' && callback(response.data);
  }).catch(function(e) {
    typeof errorCallback === 'function' && errorCallback(e);
  });
};

const patchProject = function(projectId, parameters, callback, errorCallback) {
  Vue.axios.patch(`${API_URI}/project/${projectId}/`, parameters).then(function(response) {
    callback(response.data);
  }).catch(function(e) {
    errorCallback(e);
  });
};

const deleteProject = function(projectId, callback, errorCallback) {
  const url = `${API_URI}/project/${projectId}/`;
  Vue.axios.delete(url).then(function(response) {
    callback(response.data);
  }).catch(function(e) {
    if (typeof errorCallback === 'function') {
      errorCallback(e);
    }
  });
};

const loadCompoundsFromFile = async function(files, callback, errorCallback) {
  const formData = new FormData();
  for (const name in files) {
    formData.append(name, files[name]);
  }
  // formData.append('parameters', JSON.stringify(parameters));
  const config = {};
  if (files.length > 0) {
    config.headers = {
      'content-type': 'multipart/form-data'
    };
  }

  await Vue.axios.post(
    `${API_URI}/project_design/read_compounds/`,
    formData,
    config
  ).then(async function(response) {
    callback(response.data);
  }).catch(function(e) {
    errorCallback(e);
  });
};

const getComparableHeaders = function(ids, callback, errorCallback) {
  Vue.axios.get(
    `${API_URI}/project_design/comparable_headers/`,
    {
      params: { ids: ids }
    }
  ).then(function(response) {
    callback(response.data);
  }).catch(function(e) {
    typeof errorCallback === 'function' && errorCallback(e);
  });
};

const compareDesigns = function(ids, header, callback, errorCallback) {
  Vue.axios.post(
    `${API_URI}/project_design/compare/`,
    {
      ids: ids,
      header: header
    }
  ).then(function(response) {
    callback(response.data);
  }).catch(function(e) {
    typeof errorCallback === 'function' && errorCallback(e);
  });
};

const loadManageableModels = function(params, target, callback, errorCallback) {
  Vue.axios.get(`${API_URI}/ai/manageable_modules/${target}/`,
    { params: params }
  ).then(function(response) {
    callback(response.data);
  }).catch(function(e) {
    typeof errorCallback === 'function' && errorCallback(e);
  });
};

const getMLMTypes = function(callback, errorCallback) {
  Vue.axios.get(`${API_URI}/const/mlm_type/`).then(function(response) {
    callback(response.data);
  }).catch(function(e) {
    errorCallback(e);
  });
};

const getMLMCategories = function(callback, errorCallback) {
  Vue.axios.get(`${API_URI}/const/mlm_category/`).then(function(response) {
    callback(response.data);
  }).catch(function(e) {
    errorCallback(e);
  });
};

const registerAIModel = function(parameters, files, callback, errorCallback) {
  const formData = new FormData();
  for (const name in files) {
    formData.append(name, files[name]);
  }
  formData.append('parameters', JSON.stringify(parameters));
  const config = {};
  if (files.length > 0) {
    config.headers = {
      'content-type': 'multipart/form-data'
    };
  }
  let req = null;
  if (parameters.id) {
    req = Vue.axios.put(`${API_URI}/ai/${parameters.id}/`, formData, config);
  } else {
    req = Vue.axios.post(`${API_URI}/ai/`, formData, config);
  }

  req.then(function(response) {
    callback(response.data);
  }).catch(function(e) {
    errorCallback(e);
  });
};

const getAIModelInputs = function(id, callback, errorCallback, isReadOnly = false) {
  Vue.axios.get(`${API_URI}/ai/${id}/input_parameters/`, {
    params: {
      ...(isReadOnly ? { readonly: true } : {})
    }
  }).then(function(response) {
    callback(response.data);
  }).catch(function(e) {
    errorCallback(e);
  });
};

const getAIModelOutputs = function(id, callback, errorCallback, isReadOnly = false) {
  Vue.axios.get(`${API_URI}/ai/${id}/output_files/`, {
    params: {
      ...(isReadOnly ? { readonly: true } : {})
    }
  }).then(function(response) {
    callback(response.data);
  }).catch(function(e) {
    errorCallback(e);
  });
};

const getMLTests = function(moduleId, callback, errorCallback) {
  Vue.axios.get(
    `${API_URI}/test_execution/?module_id=${moduleId}`
  ).then(function(response) {
    callback(response.data);
  }).catch(function(e) {
    errorCallback(e);
  });
};

const postMLTest = function(parameters, files, callback, errorCallback) {
  const formData = new FormData();
  for (const name in files) {
    if (Array.isArray(files[name])) {
      for (const file of files[name]) {
        formData.append(name, file);
      }
    } else {
      formData.append(name, files[name]);
    }
  }
  formData.append('parameters', JSON.stringify(parameters));
  const config = {};
  if (files.length > 0) {
    config.headers = {
      'content-type': 'multipart/form-data'
    };
  }

  Vue.axios.post(
    `${API_URI}/test_execution/`,
    formData,
    config
  ).then(function(response) {
    callback(response.data);
  }).catch(function(e) {
    errorCallback(e);
  });
};

const downloadTestFile = function(testId, filename,
  callback, errorCallback) {
  Vue.axios.get(
    `${API_URI}/test_execution/${testId}/?file=${filename}`
  ).then(function(response) {
    callback(response);
  }).catch(function(e) {
    errorCallback(e);
  });
};

const downloadConfigFile = function(moduleId, callback, errorCallback, isReadOnly = false) {
  Vue.axios.get(
    `${API_URI}/ai/${moduleId}/download_config/`,
    {
      params: {
        ...(isReadOnly ? { readonly: true } : {})
      }
    }
  ).then(function(response) {
    callback(response);
  }).catch(function(e) {
    errorCallback(e);
  });
};

const downloadDocumentFile = function(moduleId, callback, errorCallback, isReadOnly = false) {
  Vue.axios.get(
    `${API_URI}/ai/${moduleId}/download_document/`,
    {
      responseType: 'blob',
      params: {
        ...(isReadOnly ? { readonly: true } : {})
      }
    }
  ).then(function(response) {
    callback(response);
  }).catch(function(e) {
    errorCallback(e);
  });
};

const downloadPredictionResult = function(designId, callback, errorCallback) {
  Vue.axios.get(
    `${API_URI}/project_design/${designId}/download_prediction_result/`
  ).then(function(response) {
    callback(response);
  }).catch(function(e) {
    errorCallback(e);
  });
};

const downloadFileHelper = function(response, filename, json = false) {
  let content = response.data;
  // For JSON content
  if (json) {
    content = JSON.stringify(content, null, 2);
  }
  const url = new Blob([content], {
    type: response.headers['content-type']
  });
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(url);
  link.download = filename;
  link.click();
  window.URL.revokeObjectURL(url);
  link.remove();
};

const registerWorkflow = function(data, files, callback, errorCallback) {
  const formData = new FormData();
  formData.append('workflow', JSON.stringify(data));

  Vue.axios.post(`${API_URI}/workflow/`, formData).then(function(response) {
    callback(response.data);
  }).catch(function(e) {
    errorCallback(e);
  });
};

const getWorkflow = function(id, callback, errorCallback) {
  Vue.axios.get(`${API_URI}/workflow/${id}/`).then(function(response) {
    callback(response.data);
  }).catch(function(e) {
    errorCallback(e);
  });
};
// eslint-disable-next-line vue/max-len
const executeWorkflow = function(id, parameters, files, memo, visibility, group, callback, errorCallback) {
  const formData = new FormData();
  for (const name in files) {
    if (Array.isArray(files[name])) {
      for (const file of files[name]) {
        formData.append(name, file);
      }
    } else {
      formData.append(name, files[name]);
    }
  }
  formData.append('parameters', JSON.stringify(parameters));
  formData.append('memo', memo);
  formData.append('visibility', visibility);
  formData.append('group', group);

  const config = {};
  if (files.length > 0) {
    config.headers = {
      'content-type': 'multipart/form-data'
    };
  }
  Vue.axios.post(
    `${API_URI}/workflow/execute/${id}/`,
    formData,
    config
  ).then(function(response) {
    callback(response.data);
  }).catch(function(e) {
    errorCallback(e);
  });
};

const deleteWorkflow = function(workflowId, callback, errorCallback) {
  const url = `${API_URI}/workflow/${workflowId}/`;
  Vue.axios.delete(url).then(function(response) {
    callback(response.data);
  }).catch(function(e) {
    if (typeof errorCallback === 'function') {
      errorCallback(e);
    }
  });
};

const getWorkflowExecution = function(id, callback, errorCallback) {
  Vue.axios.get(`${API_URI}/workflow_execution/${id}/`).then(function(response) {
    callback(response.data);
  }).catch(function(e) {
    errorCallback(e);
  });
};

const deleteWorkflowExecution = function(id, callback, errorCallback) {
  Vue.axios.delete(`${API_URI}/workflow_execution/${id}/`).then(function(response) {
    callback(response.data);
  }).catch(function(e) {
    errorCallback(e);
  });
};

// eslint-disable-next-line vue/max-len
const loadWorkflowExecutionOutputContents = async function(id, dataTableOptions, controllerSignal, callback, errorCallback) {
  await Vue.axios.get(
    `${API_URI}/workflow_execution/${id}/main_output_file_contents/`, {
      params: {
        page: dataTableOptions.page,
        page_size: dataTableOptions.itemsPerPage,
        sort_by: dataTableOptions.sortBy,
        sort_desc: dataTableOptions.sortDesc
      },
      paramsSerializer: paramsSerializer,
      signal: controllerSignal
    }).then(function(response) {
    if (response) {
      callback(response.data);
    }
  }).catch(function(e) {
    typeof errorCallback === 'function' && errorCallback(e);
  });
};

const downloadWorkflowExecutionFile = function(id, fileName, errorCallback) {
  const config = {
    params: { name: fileName },
    paramsSerializer: paramsSerializer
  };
  Vue.axios.get(`${API_URI}/workflow_execution/${id}/file`, config
  ).then(function(response) {
    const ext = fileName.split('.').pop();
    downloadFileHelper(response, fileName, ext === 'json');
  }).catch(function(e) {
    errorCallback(e);
  });
};

const getWorkflowExecutionInputs = function(id, callback, errorCallback) {
  Vue.axiosNoResponseInterceptor.get(
    `${API_URI}/workflow_execution/${id}/inputs/`
  ).then(function(response) {
    callback(response.data);
  }).catch(function(e) {
    errorCallback(e);
  });
};

const downloadWorkFlowExecutionInputFile = function(id, fileName, step, errorCallback) {
  const config = {
    params: { name: fileName, inputFile: 'true', wfstep: step },
    paramsSerializer: paramsSerializer
  };
  Vue.axios.get(
    `${API_URI}/workflow_execution/${id}/file/`, config
  ).then(function(response) {
    const ext = fileName.split('.').pop();
    downloadFileHelper(response, fileName, ext === 'json');
  }).catch(function(e) {
    errorCallback(e);
  });
};

// eslint-disable-next-line vue/max-len
const registerProfiles = async function(parameters, sgModelFiles, filterModelFiles, designId, callback, errorCallback) {
  const formData = new FormData();
  // Upload files input to use in SG
  for (const name in sgModelFiles) {
    if (Array.isArray(sgModelFiles[name])) {
      for (const file of sgModelFiles[name]) {
        formData.append(name, file);
      }
    } else {
      formData.append(name, sgModelFiles[name]);
    }
  }
  // Upload files input to use in filter model in PD execution
  for (const filterId in filterModelFiles) {
    for (const fieldName in filterModelFiles[filterId]) {
      if (Array.isArray(filterModelFiles[filterId][fieldName])) {
        for (const file of filterModelFiles[filterId][fieldName]) {
          formData.append(fieldName, file);
        }
      } else {
        formData.append(fieldName, filterModelFiles[filterId][fieldName]);
      }
    }
  }
  for (const target of parameters.targets) {
    // Upload files input to use in profile prediction
    for (const name in target.files || {}) {
      if (Array.isArray(target.files[name])) {
        for (const file of target.files[name]) {
          formData.append(name, file);
        }
      } else {
        formData.append(name, target.files[name]);
      }
    }
    // Upload files input to use in reward related to profile prediction
    for (const name in target.os_files || {}) {
      if (Array.isArray(target.os_files[name])) {
        for (const file of target.os_files[name]) {
          formData.append(name, file);
        }
      } else {
        formData.append(name, target.os_files[name]);
      }
    }
  }
  formData.append('parameters', JSON.stringify(parameters));
  const config = {};
  config.headers = {
    'Content-Type': 'multipart/form-data'
  };

  if (designId) {
    await Vue.axios.put(
      `${API_URI}/project_design/${designId}/`,
      formData,
      config
    ).then(async function(response) {
      await callback(response.data);
    }).catch(function(e) {
      errorCallback(e);
    });
  } else {
    await Vue.axios.post(
      `${API_URI}/project_design/`,
      formData,
      config
    ).then(async function(response) {
      await callback(response.data);
    }).catch(function(e) {
      errorCallback(e);
    });
  }
};

const registerPreset = async function(parameters, callback, errorCallback) {
  await Vue.axios.post(
    `${API_URI}/presets/`,
    parameters
  ).then(function(response) {
    callback(response.data);
  }).catch(function(e) {
    errorCallback(e);
  });
};

const loadPresets = async function(callback, errorCallback) {
  await Vue.axios.get(
    `${API_URI}/presets/`, {
      transformResponse: [
        (data) => {
          return camelcaseKeys(JSON.parse(data), { deep: true });
        }
      ]
    }
  ).then(function(response) {
    callback(response.data);
  }).catch(function(e) {
    errorCallback(e);
  });
};

const loadScalingTypes = function(callback, errorCallback) {
  Vue.axios.get(`${API_URI}/const/scaling_type/`).then(function(response) {
    callback(response.data);
  }).catch(function(e) {
    errorCallback(e);
  });
};

const loadDesignProfile = async function(designId, callback, errorCallback) {
  await Vue.axios.get(
    `${API_URI}/project_design/${designId}/profile/`
  ).then(function(response) {
    callback(response.data);
  }).catch(function(e) {
    errorCallback(e);
  });
};

const deleteProfilePreset = async function(profileId, callback, errorCallback) {
  await Vue.axios.delete(
    `${API_URI}/presets/${profileId}/`
  ).then(function(response) {
    callback(response.data);
  }).catch(function(e) {
    errorCallback(e);
  });
};

const loadProjectDesigns = function(config, callback, errorCallback) {
  Vue.axios.get(
    `${API_URI}/project_design/`,
    config
  ).then(function(response) {
    callback(response.data);
  }).catch(function(e) {
    errorCallback(e);
  });
};

const loadProjectDesign = async function(designId, params, callback, errorCallback) {
  await Vue.axios.get(
    `${API_URI}/project_design/${designId}/`,
    params
  ).then(function(response) {
    callback(response.data);
  }).catch(function(e) {
    errorCallback(e);
  });
};

const deleteProjectDesign = async function(designId, callback, errorCallback) {
  await Vue.axios.delete(
    `${API_URI}/project_design/${designId}/`
  ).then(function(response) {
    callback(response.data);
  }).catch(function(e) {
    errorCallback(e);
  });
};

const loadProjectDesignResults = async function(id, dataTableOptions, filterNames, numericFilters, controllerSignal, callback, errorCallback) {
  await Vue.axios.get(
    `${API_URI}/project_design/${id}/result/`, {
      params: {
        page: dataTableOptions.page,
        page_size: dataTableOptions.itemsPerPage,
        sort_by: dataTableOptions.sortBy,
        sort_desc: dataTableOptions.sortDesc,
        filter_names: filterNames,
        numeric_filters: numericFilters
      },
      paramsSerializer: paramsSerializer,
      signal: controllerSignal
    }).then(function(response) {
    // If the request is aborted, there is no response
    if (response) {
      callback(response.data);
    }
  }).catch(function(e) {
    typeof errorCallback === 'function' && errorCallback(e);
  });
};

const loadProjectDesignInputs = function(id, callback, errorCallback) {
  Vue.axios.get(
    `${API_URI}/project_design_execution/${id}/inputs/`
  ).then(function(response) {
    callback(response.data);
  }).catch(function(e) {
    errorCallback(e);
  });
};

const downloadProjectDesignOutputFile = function(id, filters = []) {
  Vue.axios.get(
    `${API_URI}/project_design/${id}/download_results/`,
    { params: filters.length > 0 ? { filter_names: filters } : null }
  ).then(function(response) {
    const blob = new Blob([response.data], {
      type: response.headers['content-type']
    });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = response.headers['content-disposition'].split('filename=')[1];
    link.click();
    window.URL.revokeObjectURL(blob);
    link.remove();
  }).catch(function(e) {
    console.log(e);
  });
};

const downloadManual = function(errorCallback, page) {
  Vue.axios.get(
    `${API_URI}/manual/`,
    { responseType: 'blob' }
  ).then(function(response) {
    openPDFHelper(response, page);
  }).catch(function(e) {
    errorCallback(e);
  });
};

const openPDFHelper = function(response, page) {
  const content = response.data;
  const url = new Blob([content], {
    type: response.headers['content-type']
  });
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(url) + `#page=${page || 1}`;
  link.target = '_blank';
  link.click();
  window.URL.revokeObjectURL(url);
  link.remove();
};

const getExecutionVisibility = function(callback, errorCallback) {
  Vue.axios.get(`${API_URI}/const/exec_visibility/`).then(function(response) {
    callback(response.data);
  }).catch(function(e) {
    errorCallback(e);
  });
};

const getWorkflowVisibility = function(callback, errorCallback) {
  Vue.axios.get(`${API_URI}/const/workflow_visibility/`).then(function(response) {
    callback(response.data);
  }).catch(function(e) {
    errorCallback(e);
  });
};

const cancelExecution = function(executionId, executionType, callback, errorCallback) {
  Vue.axios.post(
    `${API_URI}/cancel_execution/`,
    {
      execution_id: executionId,
      execution_type: executionType
    }
  ).then(function(response) {
    callback(response.data);
  }).catch(function(e) {
    errorCallback(e);
  });
};

const getJobsList = function(callback, errorCallback) {
  Vue.axios.get(
    `${API_URI}/jobs/`
  ).then(function(response) {
    callback(response.data);
  }).catch(function(e) {
    errorCallback(e);
  });
};

const downloadModuleInfoFile = function(moduleId, callback, errorCallback) {
  Vue.axios.get(
    `${API_URI}/ai/${moduleId}/download_info_file/`,
    {
      responseType: 'blob'
    }
  ).then(function(response) {
    callback(response);
  }).catch(function(e) {
    errorCallback(e);
  });
};

const getRewards = function(callback, errorCallback) {
  Vue.axios.get(`${API_URI}/rewards/`).then(function(response) {
    callback(response.data);
  }).catch(function(e) {
    errorCallback(e);
  });
};

const getVisibilityChioces = function(callback, errorCallback) {
  Vue.axios.get(`${API_URI}/const/proj_visibility/`).then(function(response) {
    callback(response.data);
  }).catch(function(e) {
    errorCallback(e);
  });
};

const getModelIdPolicyOptions = function(callback, errorCallback) {
  Vue.axios.get(`${API_URI}/const/model_id_policy/`).then(function(response) {
    callback(response.data);
  }).catch(function(e) {
    errorCallback(e);
  });
};

const getAllowedExtPtsOption = function(callback, errorCallback) {
  Vue.axios.get(`${API_URI}/const/ext_pts_options/`).then(function(response) {
    callback(response.data);
  }).catch(function(e) {
    errorCallback(e);
  });
};

const postModelsLink = async function(params, callback, errorCallback) {
  await Vue.axios.post(
    `${API_URI}/module_objective_server/`, params
  ).then(function(response) {
    callback(response);
  }).catch(function(e) {
    errorCallback(e);
  });
};

const deleteModelsLink = async function(id, callback, errorCallback) {
  await Vue.axios.delete(
    `${API_URI}/module_objective_server/${id}`
  ).then(function(response) {
    callback(response);
  }).catch(function(e) {
    errorCallback(e);
  });
};

const loadRnnDirOptions = async function(callback, errorCallback) {
  await Vue.axios.get(
    `${API_URI}/rnn_dirs/`
  ).then(function(response) {
    callback(response.data);
  }).catch(function(e) {
    errorCallback(e);
  });
};

const createChildDesign = async function(id, params, callback, errorCallback) {
  await Vue.axios.post(
    `${API_URI}/project_design/${id}/create_child/`, params
  ).then(function(response) {
    callback(response.data);
  }).catch(function(e) {
    errorCallback(e);
  });
};

const getDesignSettings = async function(id, callback, errorCallback) {
  await Vue.axios.get(
    `${API_URI}/project_design/${id}/design_settings/`
  ).then(function(response) {
    callback(response.data);
  }).catch(function(e) {
    errorCallback(e);
  });
};

const getRewardColumns = async function(id, callback, errorCallback) {
  await Vue.axios.get(
    `${API_URI}/project_design/${id}/reward_columns/`
  ).then(function(response) {
    callback(response.data);
  }).catch(function(e) {
    errorCallback(e);
  });
};

const updateUserPassword = function(dataToSend, callback, errorCallback) {
  Vue.axios.post(`${AUTH_URI}/password_update/`, dataToSend)
    .then(function(response) {
      callback(response.data);
    }).catch(function(e) {
      errorCallback(e);
    });
};

export default {
  getProcessExecutionStatus,
  authenticateUser,
  refreshToken,
  getUserInfo,
  getNonMembers,
  getGroupMembers,
  addGroupMember,
  removeGroupMember,
  getMLModules,
  getModelLinkableModules,
  loadWorkflows,
  getMLModule,
  getMLModuleWithVersionInfo,
  patchMLModules,
  getMLModuleByName,
  deleteMLModule,
  executeMLModule,
  loadExecutions,
  getExecution,
  deleteExecution,
  getExecutionOutput,
  downloadExecutionFile,
  getExecutionInputs,
  downloadExecutionInputFile,
  downloadExecutionWorkingFolder,
  registerProject,
  loadProjects,
  loadProject,
  patchProject,
  deleteProject,
  loadCompoundsFromFile,
  getComparableHeaders,
  compareDesigns,
  loadManageableModels,
  getMLMTypes,
  getMLMCategories,
  registerAIModel,
  getAIModelInputs,
  getAIModelOutputs,
  getMLTests,
  postMLTest,
  downloadTestFile,
  downloadConfigFile,
  downloadDocumentFile,
  downloadPredictionResult,
  downloadFileHelper,
  registerWorkflow,
  getWorkflow,
  executeWorkflow,
  deleteWorkflow,
  getWorkflowExecution,
  deleteWorkflowExecution,
  loadWorkflowExecutionOutputContents,
  downloadWorkflowExecutionFile,
  getWorkflowExecutionInputs,
  downloadWorkFlowExecutionInputFile,
  registerProfiles,
  registerPreset,
  loadPresets,
  loadScalingTypes,
  loadDesignProfile,
  loadProjectDesigns,
  loadProjectDesign,
  deleteProjectDesign,
  loadProjectDesignResults,
  loadProjectDesignInputs,
  downloadProjectDesignOutputFile,
  downloadManual,
  openPDFHelper,
  getExecutionVisibility,
  getWorkflowVisibility,
  cancelExecution,
  getJobsList,
  downloadModuleInfoFile,
  getRewards,
  getVisibilityChioces,
  getModelIdPolicyOptions,
  getAllowedExtPtsOption,
  postModelsLink,
  deleteModelsLink,
  deleteProfilePreset,
  loadRnnDirOptions,
  createChildDesign,
  getDesignSettings,
  getRewardColumns,
  updateUserPassword
};
