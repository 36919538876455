<template>
  <v-card class="pa-5">
    <v-card-title>
      {{ title }}
    </v-card-title>

    <v-text-field
      v-model="memo"
      :placeholder="placeholder"
      @keypress.native.enter="submit"
    />
    <v-card-actions>
      <v-btn color="alert" @click="cancel">{{ cancelButtonLabel }}</v-btn>
      <v-spacer />
      <v-btn color="primary" @click="submit">{{ submitButtonLabel }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'MemoDialog',
  components: {
  },
  props: {
    title: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      default: ''
    },
    cancelButtonLabel: {
      type: String,
      required: true
    },
    submitButtonLabel: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      memo: ''
    };
  },
  methods: {
    cancel() {
      this.$emit('cancel');
    },
    submit() {
      this.$emit('confirm', this.memo);
    }
  }
};
</script>
