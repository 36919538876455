<template>
  <v-container class="pb-0">
    <ExecutionTable />
  </v-container>
</template>

<script>
import ExecutionTable from '../components/ExecutionTable';

export default {
  name: 'MLPlatform',
  components: {
    ExecutionTable: ExecutionTable
  },
  mounted() {
    this.checkLoggedIn(this.$session);
  }
};
</script>
