<template>
  <v-container class="pb-0">
    <JobsTable />
  </v-container>
</template>

<script>
import JobsTable from '../components/JobsTable.vue';

export default {
  name: 'JobsTableList',
  components: {
    JobsTable
  },
  mounted() {
    this.checkLoggedIn(this.$session);
  }
};
</script>
