<template>
  <span
    class="read-more-switch"
    @click="onClick"
  >
    {{
      showFull
        ? "Hide description"
        : "Read full description"
    }}
  </span>
</template>

<script>
export default {
  name: 'ReadMoreSwitch',

  data() {
    return {
      showFull: false
    };
  },
  methods: {
    onClick() {
      this.showFull = !this.showFull;
      this.$emit('onClick');
    }
  }
};
</script>

<style scoped>
.read-more-switch {
  cursor:pointer;
  color: #1976d2;
}
</style>
