<template>
  <v-dialog v-model="dialog" width="600">
    <template #activator="{ on, attrs }">
      <div>
        <v-btn
          color="primary"
          v-bind="attrs"
          v-on="on"
        >
          {{ readonly ? 'See options' : 'Set Options' }}
        </v-btn>
        <div v-show="isValid===false" class="v-messages error--text custom">
          At least one options is needed.
        </div>
      </div>
    </template>
    <v-card>
      <v-card-title class="text-h5 grey lighten-2 d-flex justify-space-between">
        {{ readonly ? 'See options' : 'Set Options' }}
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form
            v-if="!readonly"
            ref="optionForm1"
          >
            <v-row>
              <v-col cols="4">
                <v-text-field
                  v-model="newOption.text"
                  label="Display Text"
                  :rules="[v => localOptions.every(o => o.text !== v?.trim()) || 'Display text must be unique']"
                />
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="newOption.value"
                  label="Value"
                  :rules="[v => !!v || 'Value is required',
                           v => localOptions.every(o => o.value !== v?.trim()) || 'Value must be unique']"
                  @keyup.enter="addItem"
                />
              </v-col>
              <v-col cols="4" class="d-flex justify-center align-center">
                <v-btn color="primary" @click="addItem">Add Item</v-btn>
              </v-col>
            </v-row>
          </v-form>
          <v-row>
            <v-col cols="12">
              <v-data-table
                :headers="headers"
                :items="options"
                item-key="value"
                class="elevation-1"
                hide-default-footer
              >
                <template #item.action="{ item }">
                  <v-btn color="error" icon @click="removeItem(item)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn color="error" @click="dialog=false">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'OptionSetupForSelectArgument',
  props: {
    options: {
      type: Array,
      default: () => []
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialog: false,
      headers: [
        { value: 'text', text: 'Display Text' },
        { value: 'value', text: 'Value' }
      ],
      newOption: { text: '', value: '' },
      localOptions: [],
      isValid: null
    };
  },
  watch: {
    dialog: {
      handler: function() {
        if (!(this.readonly || this.dialog)) {
          this.validate();
        }
      }
    }
  },
  mounted() {
    this.localOptions = this.options;
    if (!this.readonly) {
      this.headers.push({ value: 'action', text: '' });
    }
  },
  methods: {
    addItem() {
      if (!this.$refs.optionForm1.validate()) return;
      this.localOptions.push({
        text: this.newOption.text?.trim() || this.newOption.value?.trim(),
        value: this.newOption.value?.trim()
      });
      this.$refs.optionForm1.reset();
      this.$emit('update', this.localOptions);
    },
    removeItem(item) {
      const index = this.localOptions.findIndex(o => o.value === item.value);
      this.localOptions.splice(index, 1);
      this.$emit('update', this.localOptions);
    },
    validate() {
      if (this.options.length > 0) {
        this.isValid = true;
      } else {
        this.isValid = false;
      }
      return this.isValid;
    }
  }
};
</script>
