<template>
  <v-container>
    <v-row>
      <v-toolbar dense dark color="primary">
        <v-row>
          <v-col class="d-flex justify-space-around">
            <v-toolbar-title class="white--text">
              DAIIA AI プラットフォーム
            </v-toolbar-title>
          </v-col>
        </v-row>
      </v-toolbar>
    </v-row>
    <v-row justify="end">
      <v-sheet width="300px" class="my-4">
        <v-text-field
          v-if="false"
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        />
      </v-sheet>
    </v-row>
    <v-row>
      <PortalCard
        title="AI プラットフォーム"
        :start-button-item="aipStartButtonItem"
        :manual-items="aipManualItems"
        :link-items="aipLinkItems"
      />
      <PortalCard
        title="Federated Learningシステム"
        :in-preparation="true"
        theme-color="success lighten-1"
      />
      <PortalCard
        title="オミクス情報に基づく設計システム"
        :in-preparation="true"
        theme-color="error lighten-3"
      />
    </v-row>
  </v-container>
</template>

<script>
import PortalCard from '@/components/PortalCard';
export default {
  name: 'Portal',
  components: {
    PortalCard
  },
  data: () => ({
    search: '',
    aipStartButtonItem: {
      text: 'システムを\n利用する',
      to: '/'
    },
    aipManualItems: [
      { text: '簡易マニュアル', icon: 'mdi-help-box-outline', to: '創薬AIプラットフォーム_ユーザーマニュアル.pdf' },
      { text: '詳細マニュアル', icon: 'mdi-help-box-multiple-outline' },
      { text: 'ヘルプ', icon: 'mdi-help-circle-outline' }
    ],
    aipLinkItems: [
      { text: '登録済みモデル一覧', icon: 'mdi-head-dots-horizontal-outline', to: '創薬AIプラットフォーム_モデル一覧.xlsx' },
      { text: 'サンプルファイル', icon: 'mdi-file-document-check-outline', to: '創薬AIプラットフォーム_samples.zip' }
    ]
  }),
  methods: {}
};
</script>
