<template>
  <div>
    <span v-if="model.status === 'Creation failed'">
      <v-tooltip top>
        <template #activator="{ on, attrs }">
          {{ model.status }}
          <sup>
            <v-icon dense small v-bind="attrs" v-on="on">
              mdi-help-circle-outline
            </v-icon>
          </sup>
        </template>
        <span>
          {{ model.reason }}
        </span>
      </v-tooltip>
    </span>
    <div v-else>
      <div v-for="(status, i) in statusList" :key="i" class="float-left">
        <span :class="classesForStatus(model.status, i)">
          {{ changedStatusText(model.status, status) }}
          <v-tooltip
            v-if="model.status === 'Registered' && status === 'Verified'"
            top
          >
            <template #activator="{ on, attrs }">
              <sup>
                <v-icon
                  dense
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-help-circle-outline
                </v-icon>
              </sup>
            </template>
            <span>
              動作確認できるまで、公開することができない。
            </span>
          </v-tooltip>
        </span>
        <span v-if="prevStatus(model.status, i)" class="d-inline">
          &#10003;
        </span>
        <span v-if="i < statusList.length - 1" class="d-inline">
          &nbsp;&#8208;&nbsp;
        </span>
      </div>
    </div>
    <span v-if="model.testing"> / Test in progress </span>
  </div>
</template>

<script>
export default {
  name: 'MLMStatus',
  components: {},
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      statusList: ['Creation in progress', 'Registered', 'Verified']
    };
  },
  mounted() {},
  methods: {
    prevStatus(status, currIndex) {
      const index = this.statusList.indexOf(status);
      return index >= 0 && currIndex <= index;
    },
    classesForStatus(status, currIndex) {
      const index = this.statusList.indexOf(status);
      return {
        'd-inline': true,
        'text--disabled': index >= 0 && currIndex > index,
        'font-weight-bold': index >= 0 && currIndex <= index
      };
    },
    changedStatusText(modelStatus, status) {
      if (
        ['Registered', 'Verified'].includes(modelStatus) &&
                status === 'Creation in progress'
      ) {
        return 'Created';
      }
      return status;
    }
  }
};
</script>
